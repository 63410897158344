import { AlertOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Row, Space } from "antd";
import i18n from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUpdateAvailable } from "../../redux/application/application.selectors";
import { useRegisterSW } from "virtual:pwa-register/react";
import InstanceRenderManager from "../../utils/instanceRenderMgr";
import useCountDown from "../../utils/countdownHook";
import { useNotification } from "../../contexts/Notifications/notificationContext.jsx";

const mapStateToProps = createStructuredSelector({
  updateAvailable: selectUpdateAvailable
});

const mapDispatchToProps = (dispatch) => ({
  // setUserLanguage: language => dispatch(setUserLanguage(language))
});

export function UpdateAlert({ updateAvailable }) {
  const { t } = useTranslation();
  const [timerStarted, setTimerStarted] = useState(false);
  const [loading, setLoading] = useState(false);
  const notification = useNotification();

  const [
    timeLeft,
    {
      start //pause, resume, reset
    }
  ] = useCountDown(180000, 1000);

  const {
    offlineReady: [offlineReady],
    needRefresh: [needRefresh],
    updateServiceWorker
  } = useRegisterSW({
    onRegistered(r) {
      console.log("SW Registered:", r);
      if (r) {
        setInterval(
          () => {
            r.update();
          },
          30 * 60 * 1000
        );
      }
    },
    onRegisterError(error) {
      console.error("SW registration error", error);
    }
  });

  const ReloadNewVersion = useCallback(() => {
    setLoading(true);
    updateServiceWorker(true);
    setTimeout(() => {
      window.location.reload(true);
    }, 5000);
  }, [updateServiceWorker]);

  useEffect(() => {
    if (needRefresh) {
      start();
      setTimerStarted(true);
    }
  }, [start, needRefresh, offlineReady]);

  useEffect(() => {
    if (needRefresh && timerStarted && timeLeft < 60000) {
      notification.open({
        type: "warning",
        closable: false,
        duration: 65000,
        key: "autoupdate",
        message: t("general.actions.autoupdate", {
          time: (timeLeft / 1000).toFixed(0),
          app: InstanceRenderManager({
            imex: "$t(titles.imexonline)",
            rome: "$t(titles.romeonline)"
          })
        })
      });
    }
    if (needRefresh && timerStarted && timeLeft <= 0) {
      ReloadNewVersion();
    }
  }, [timeLeft, t, needRefresh, ReloadNewVersion, timerStarted, notification]);

  if (!needRefresh) return null;

  return (
    <Alert
      message={t("general.messages.newversiontitle", {
        app: InstanceRenderManager({
          imex: "$t(titles.imexonline)",
          rome: "$t(titles.romeonline)"
        })
      })}
      showIcon
      icon={<AlertOutlined />}
      description={
        <Row gutter={[16, 16]}>
          <Col sm={24} md={16} lg={18}>
            {t("general.messages.newversionmessage", {
              app: InstanceRenderManager({
                imex: "$t(titles.imexonline)",
                rome: "$t(titles.romeonline)"
              })
            })}
          </Col>
          <Col sm={24} md={8} lg={6}>
            <Space wrap>
              <Button onClick={() => window.open("https://imex-online.noticeable.news/", "_blank")}>
                {i18n.t("general.actions.viewreleasenotes")}
              </Button>
              <Button loading={loading} type="primary" onClick={() => ReloadNewVersion()}>
                {i18n.t("general.actions.refresh")} {`(${(timeLeft / 1000).toFixed(0)} s)`}
              </Button>
              <Button onClick={() => start(300000)}>{i18n.t("general.actions.delay")}</Button>
            </Space>
          </Col>
        </Row>
      }
      closable={false}
      type="warning"
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAlert);

import _ from "lodash";

export const CheckJobBucket = (buckets, job) => {
  const jobHours = job.labhrs.aggregate.sum.mod_lb_hrs + job.larhrs.aggregate.sum.mod_lb_hrs;

  const matchingBucket = buckets.filter((b) => (b.gte <= jobHours && b.lt ? b.lt > jobHours : true));

  return matchingBucket[0] && matchingBucket[0].id;
};

export const CalculateLoad = (currentLoad, buckets, jobsIn, jobsOut) => {
  //Add the jobs coming
  const newLoad = _.cloneDeep(currentLoad);
  jobsIn.forEach((job) => {
    const bucketId = CheckJobBucket(buckets, job);
    if (bucketId) {
      newLoad[bucketId].count = newLoad[bucketId].count + 1;
    } else {
      console.log("[Util Arr Job]Uh oh, this job doesn't fit in a bucket!", job);
    }
  });

  jobsOut.forEach((job) => {
    const bucketId = CheckJobBucket(buckets, job);
    if (bucketId) {
      newLoad[bucketId].count = newLoad[bucketId].count - 1;
      if (newLoad[bucketId].count < 0) {
        console.log("***ERROR: NEGATIVE LOAD", bucketId, job);
      }
    } else {
      console.log("[Util Out Job]Uh oh, this job doesn't fit in a bucket!", job);
    }
  });

  return newLoad;
};

import InstanceRenderManager from "../../utils/instanceRenderMgr";
import EmailActionTypes from "./email.types";

const INITIAL_STATE = {
  emailConfig: {
    messageOptions: {
      from: {
        name: "ShopName",
        address: InstanceRenderManager({
          imex: "noreply@iemx.online",
          rome: "noreply@romeonline.io"
        })
      },
      to: null,
      replyTo: null
    },
    template: { name: null, variables: {} }
  },

  open: false,
  error: null
};

const emailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EmailActionTypes.TOGGLE_EMAIL_OVERLAY_VISIBLE:
      return {
        ...state,
        open: !state.open
      };
    case EmailActionTypes.SET_EMAIL_OPTIONS:
      return {
        ...state,
        emailConfig: { ...action.payload },
        open: true
      };
    default:
      return state;
  }
};

export default emailReducer;

import TechActionTypes from "./tech.types";

const INITIAL_STATE = {
  technician: null,
  // technician: {
  //   employee_number: "101",
  //   first_name: "***HARDCODED",
  //   last_name: "IN REDUCER***",
  // },
  loginLoading: false,
  loginError: null
};

const applicationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TechActionTypes.TECH_LOGOUT:
      return {
        ...state,
        technician: null,
        loginError: null
      };
    case TechActionTypes.TECH_LOGIN_START:
      return {
        ...state,
        loginLoading: true
      };
    case TechActionTypes.TECH_LOGIN_SUCCESS:
      return {
        ...state,
        technician: action.payload,
        loginLoading: false,
        loginError: false
      };
    case TechActionTypes.TECH_LOGIN_FAILURE:
      return {
        ...state,
        loginError: action.payload,
        loginLoading: false
      };

    default:
      return state;
  }
};

export default applicationReducer;

import EmailActionTypes from "./email.types";

export const toggleEmailOverlayVisible = () => ({
  type: EmailActionTypes.TOGGLE_EMAIL_OVERLAY_VISIBLE
});

export const setEmailOptions = (options) => ({
  type: EmailActionTypes.SET_EMAIL_OPTIONS,
  payload: options
});

export const sendEmail = (email) => ({
  type: EmailActionTypes.SEND_EMAIL,
  payload: email
});

export const sendEmailSuccess = (options) => ({
  type: EmailActionTypes.SEND_EMAIL_SUCCESS
});

export const sendEmailFailure = (error) => ({
  type: EmailActionTypes.SEND_EMAIL_FAILURE,
  payload: error
});

import { gql } from "@apollo/client";

export const GET_NOTIFICATIONS = gql`
  query GetNotifications($limit: Int!, $offset: Int!, $where: notifications_bool_exp) {
    notifications(limit: $limit, offset: $offset, order_by: { created_at: desc }, where: $where) {
      id
      jobid
      associationid
      scenario_text
      fcm_text
      scenario_meta
      created_at
      read
      job {
        id
        ro_number
      }
    }
  }
`;

export const GET_UNREAD_COUNT = gql`
  query GetUnreadCount($associationid: uuid!) {
    notifications_aggregate(where: { read: { _is_null: true }, associationid: { _eq: $associationid } }) {
      aggregate {
        count
      }
    }
  }
`;

export const MARK_ALL_NOTIFICATIONS_READ = gql`
  mutation MarkAllNotificationsRead($associationid: uuid!) {
    update_notifications(
      where: { read: { _is_null: true }, associationid: { _eq: $associationid } }
      _set: { read: "now()" }
    ) {
      affected_rows
    }
  }
`;

export const MARK_NOTIFICATION_READ = gql`
  mutation MarkNotificationRead($id: uuid!) {
    update_notifications(where: { id: { _eq: $id } }, _set: { read: "now()" }) {
      returning {
        id
        read
      }
    }
  }
`;

export const UPDATE_NOTIFICATIONS_READ_FRAGMENT = gql`
  fragment UpdateNotificationRead on notifications {
    read
  }
`;

import { createSelector } from "reselect";

const selectApplication = (state) => state.application;

export const selectLoading = createSelector([selectApplication], (application) => application.loading);

export const selectBreadcrumbs = createSelector([selectApplication], (application) => application.breadcrumbs);

export const selectRecentItems = createSelector([selectApplication], (application) => application.recentItems);

export const selectScheduleLoad = createSelector([selectApplication], (application) => application.scheduleLoad.load);
export const selectPartnerVersion = createSelector([selectApplication], (application) => application.partnerVersion);

export const selectScheduleLoadCalculating = createSelector(
  [selectApplication],
  (application) => application.scheduleLoad.calculating
);

export const selectSelectedHeader = createSelector([selectApplication], (application) => application.selectedHeader);

export const selectJobReadOnly = createSelector([selectApplication], (application) => application.jobReadOnly);
export const selectOnline = createSelector([selectApplication], (application) => application.online);
export const selectProblemJobs = createSelector([selectApplication], (application) => application.problemJobs);
export const selectUpdateAvailable = createSelector([selectApplication], (application) => application.updateAvailable);
export const selectWssStatus = createSelector([selectApplication], (application) => application.wssStatus);
export const selectAlerts = createSelector([selectApplication], (application) => application.alerts);

import axios from "axios";
import { auth } from "../firebase/firebase.utils";

axios.defaults.baseURL = import.meta.env.DEV
  ? "/api/"
  : import.meta.env.VITE_APP_AXIOS_BASE_API_URL || "https://api.imex.online/";

export const axiosAuthInterceptorId = axios.interceptors.request.use(
  async (config) => {
    if (!config.headers.Authorization) {
      const token = auth.currentUser && (await auth.currentUser.getIdToken());
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

const cleanAxios = axios.create();
cleanAxios.interceptors.request.eject(axiosAuthInterceptorId);

export default cleanAxios;

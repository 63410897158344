import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en_Translation from "./en_us/common.json";
import es_Translation from "./es/common.json";
import fr_Translation from "./fr/common.json";
import { GenerateTemplates } from "../utils/RenderTemplate";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  "en-US": en_Translation,
  "fr-CA": fr_Translation,
  "es-MX": es_Translation
};
i18n
  .use(LanguageDetector) // passes i18n down to react-i18next
  .use(initReactI18next)
  .init(
    {
      //ns: ["translation"],
      resources,
      //lng: "en",
      detection: {},
      fallbackLng: "en-US",
      debug: import.meta.env.DEV,
      react: {
        useSuspense: true
      },
      //keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false, // react already safes from xss
        skipOnVariables: false
      }
    },
    (error) => {
      GenerateTemplates();
    }
  );

export default i18n;

import * as Sentry from "@sentry/react";
import { excludeGraphQLFetch } from "apollo-link-sentry";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import InstanceRenderManager from "./instanceRenderMgr";

const currentDatePST = new Date()
  .toLocaleDateString("en-US", {
    timeZone: "America/Los_Angeles",
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  })
  .split("/")
  .reverse()
  .join("-");
const sentryRelease =
  `${import.meta.env.VITE_APP_IS_TEST ? "test" : "production"}-${currentDatePST}-${process.env.VITE_GIT_COMMIT_HASH}`.trim();

if (!import.meta.env.DEV) {
  Sentry.init({
    dsn: InstanceRenderManager({
      imex: "https://fd7e89369b6b4bdc9c6c4c9f22fa4ee4@o492140.ingest.sentry.io/5651027",
      rome: "https://a6acc91c073e414196014b8484627a61@o492140.ingest.sentry.io/4504561071161344"
    }),
    release: sentryRelease,

    ignoreErrors: [
      "ResizeObserver loop",
      "ResizeObserver loop limit exceeded",
      "Module specifier, 'fs' does not start",
      "Module specifier, 'zlib' does not start with",
      "Messaging: This browser doesn't support the API's required to use the Firebase SDK.",
      "Failed to update a ServiceWorker for scope"
    ],
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration()
    ],

    tracePropagationTargets: [
      "api.imex.online",
      "api.test.imex.online",
      "db.imex.online",
      "api.romeonline.io",
      "api.test.romeonline.io",
      "db.romeonline.io"
    ],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    environment: import.meta.env.MODE,
    beforeBreadcrumb: excludeGraphQLFetch
  });
}

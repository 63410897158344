import { createSelector } from "reselect";

const selectUser = (state) => state.user;

export const selectCurrentUser = createSelector([selectUser], (user) => user.currentUser);

export const selectSignInError = createSelector([selectUser], (user) => user.error);

export const selectBodyshop = createSelector([selectUser], (user) => user.bodyshop);

export const selectInstanceConflict = createSelector([selectUser], (user) => user.conflict);

export const selectPasswordReset = createSelector([selectUser], (user) => user.passwordreset);

export const selectAuthLevel = createSelector([selectUser], (user) => user.authLevel);

export const selectLoginLoading = createSelector([selectUser], (user) => user.loginLoading);

export const selectCurrentEula = createSelector([selectUser], (user) => user.currentEula);

import { useQuery } from "@apollo/client";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import AlertComponent from "../../components/alert/alert.component";
import { QUERY_BODYSHOP } from "../../graphql/bodyshop.queries";
import { setBodyshop } from "../../redux/user/user.actions";
import TechPage from "./tech.page.component";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner.component";
import { useTranslation } from "react-i18next";
import { selectBodyshop } from "../../redux/user/user.selectors";
import { createStructuredSelector } from "reselect";

const mapStateToProps = createStructuredSelector({
  bodyshop: selectBodyshop
});

const mapDispatchToProps = (dispatch) => ({
  setBodyshop: (bs) => dispatch(setBodyshop(bs))
});

export function TechPageContainer({ bodyshop, setBodyshop }) {
  const { loading, error, data } = useQuery(QUERY_BODYSHOP, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (data) setBodyshop(data.bodyshops[0]);
  }, [data, setBodyshop]);

  if (loading || !bodyshop) return <LoadingSpinner message={t("general.labels.loadingshop")} />;
  if (error) return <AlertComponent message={error.message} type="error" />;
  return <TechPage />;
}

export default connect(mapStateToProps, mapDispatchToProps)(TechPageContainer);

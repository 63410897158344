const ApplicationActionTypes = {
  START_LOADING: "START_LOADING",
  END_LOADING: "END_LOADING",
  SET_BREAD_CRUMBS: "SET_BREAD_CRUMBS",
  CALCULATE_SCHEDULE_LOAD: "CALCULATE_SCHEDULE_LOAD",
  CALCULATE_SCHEDULE_LOAD_SUCCESS: "CALCULATE_SCHEDULE_LOAD_SUCCESS",
  CALCULATE_SCHEDULE_LOAD_FAILURE: "CALCULATE_SCHEDULE_LOAD_FAILURE",
  ADD_RECENT_ITEM: "ADD_RECENT_ITEM",
  SET_SELECTED_HEADER: "SET_SELECTED_HEADER",
  SET_JOB_READONLY: "SET_JOB_READONLY",
  SET_PARTNER_VERSION: "SET_PARTNER_VERSION",
  SET_ONLINE_STATUS: "SET_ONLINE_STATUS",
  INSERT_AUDIT_TRAIL: "INSERT_AUDIT_TRAIL",
  SET_PROBLEM_JOBS: "SET_PROBLEM_JOBS",
  SET_UPDATE_AVAILABLE: "SET_UPDATE_AVAILABLE",
  SET_WSS_STATUS: "SET_WSS_STATUS",
  ADD_ALERTS: "ADD_ALERTS"
};
export default ApplicationActionTypes;

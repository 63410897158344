import MediaActionTypes from "./media.types";

export const getJobMedia = (jobid) => ({
  type: MediaActionTypes.GET_MEDIA_FOR_JOB,
  payload: jobid
});

export const getBillMedia = ({ jobid, invoice_number }) => {
  return {
    type: MediaActionTypes.GET_MEDIA_FOR_BILL,
    payload: { jobid, invoice_number }
  };
};

export const setJobMedia = ({ jobid, media }) => ({
  type: MediaActionTypes.SET_MEDIA_FOR_JOB,
  payload: { jobid, media }
});

export const addMediaForJob = ({ jobid, media }) => ({
  type: MediaActionTypes.ADD_MEDIA_FOR_JOB,
  payload: { jobid, media }
});

export const getJobMediaError = ({ error, message }) => ({
  type: MediaActionTypes.GET_MEDIA_FOR_JOB_ERROR,
  payload: { error, message }
});

export const toggleMediaSelected = ({ jobid, filename }) => ({
  type: MediaActionTypes.TOGGLE_MEDIA_SELECTED,
  payload: { jobid, filename }
});

export const deselectAllMediaForJob = ({ jobid }) => ({
  type: MediaActionTypes.DESELECT_ALL_MEDIA_FOR_JOB,
  payload: { jobid }
});

export const selectAllmediaForJob = ({ jobid }) => ({
  type: MediaActionTypes.SELECT_ALL_MEDIA_FOR_JOB,
  payload: { jobid }
});

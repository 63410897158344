import { DetermineFileType } from "../documents-upload/documents-upload.utility";

export const GenerateSrcUrl = (value) => {
  let extension = value.extension;
  if (extension && extension.toLowerCase().includes("heic")) extension = "jpg";

  return `${import.meta.env.VITE_APP_CLOUDINARY_ENDPOINT}/${DetermineFileType(
    value.type
  )}/upload/${value.key}${extension ? `.${extension}` : ""}`;
};

export const GenerateThumbUrl = (value) => {
  let extension = value.extension;
  if (extension && extension.toLowerCase().includes("heic")) extension = "jpg";
  else if (DetermineFileType(value.type) !== "image" || (value.type && value.type.includes("application")))
    extension = "jpg";

  return `${import.meta.env.VITE_APP_CLOUDINARY_ENDPOINT}/${DetermineFileType(
    value.type
  )}/upload/${import.meta.env.VITE_APP_CLOUDINARY_THUMB_TRANSFORMATIONS}/${
    value.key
  }${extension ? `.${extension}` : ""}`;
};

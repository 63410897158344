import React from "react";
import { ProductFruits } from "react-product-fruits";
import PropTypes from "prop-types";

const ProductFruitsWrapper = React.memo(({ currentUser, workspaceCode }) => {
  return (
    workspaceCode &&
    currentUser?.authorized === true &&
    currentUser?.email && (
      <ProductFruits
        lifeCycle="unmount"
        workspaceCode={workspaceCode}
        debug
        language="en"
        user={{
          email: currentUser.email,
          username: currentUser.email
        }}
      />
    )
  );
});

export default ProductFruitsWrapper;

ProductFruitsWrapper.propTypes = {
  currentUser: PropTypes.shape({
    authorized: PropTypes.bool,
    email: PropTypes.string
  }),
  workspaceCode: PropTypes.string
};

import ApplicationActionTypes from "./application.types";

const INITIAL_STATE = {
  loading: false,
  online: true,
  wssStatus: "disconnected",
  updateAvailable: false,
  breadcrumbs: [],
  recentItems: [],
  selectedHeader: "home",
  problemJobs: [],
  scheduleLoad: {
    load: {},
    calculating: false,
    error: null
  },
  jobReadOnly: false,
  partnerVersion: null,
  alerts: {}
};

const applicationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ApplicationActionTypes.SET_UPDATE_AVAILABLE:
      return {
        ...state,
        updateAvailable: action.payload
      };
    case ApplicationActionTypes.SET_SELECTED_HEADER:
      return {
        ...state,
        selectedHeader: action.payload
      };
    case ApplicationActionTypes.SET_ONLINE_STATUS:
      return {
        ...state,
        online: action.payload
      };
    case ApplicationActionTypes.ADD_RECENT_ITEM:
      return {
        ...state,
        recentItems: updateRecentItemsArray(state, action.payload)
      };
    case ApplicationActionTypes.SET_BREAD_CRUMBS:
      return {
        ...state,
        breadcrumbs: action.payload
      };
    case ApplicationActionTypes.CALCULATE_SCHEDULE_LOAD:
      return {
        ...state,
        problemJobs: [],
        scheduleLoad: { ...state.scheduleLoad, calculating: true, error: null }
      };
    case ApplicationActionTypes.CALCULATE_SCHEDULE_LOAD_SUCCESS:
      return {
        ...state,
        scheduleLoad: {
          ...state.scheduleLoad,
          load: action.payload,
          calculating: false
        }
      };
    case ApplicationActionTypes.CALCULATE_SCHEDULE_LOAD_FAILURE:
      return {
        ...state,
        scheduleLoad: {
          ...state.scheduleLoad,
          calculating: false,
          error: action.payload
        }
      };
    case ApplicationActionTypes.START_LOADING:
      return {
        ...state,
        loading: true
      };
    case ApplicationActionTypes.END_LOADING:
      return {
        ...state,
        loading: false
      };

    case ApplicationActionTypes.SET_JOB_READONLY:
      return { ...state, jobReadOnly: action.payload };

    case ApplicationActionTypes.SET_PARTNER_VERSION:
      return { ...state, partnerVersion: action.payload };
    case ApplicationActionTypes.SET_PROBLEM_JOBS: {
      return { ...state, problemJobs: action.payload };
    }
    case ApplicationActionTypes.SET_WSS_STATUS: {
      return { ...state, wssStatus: action.payload };
    }

    case ApplicationActionTypes.ADD_ALERTS: {
      const newAlertsMap = { ...state.alerts };

      action.payload.alerts.forEach((alert) => {
        newAlertsMap[alert.id] = alert;
      });
      return {
        ...state,
        alerts: newAlertsMap
      };
    }
    default:
      return state;
  }
};

export default applicationReducer;

const updateRecentItemsArray = (state, newItem) => {
  //Check to see if the new item is in the list.
  const matchingIndex = state.recentItems.findIndex((i) => i.id === newItem.id);

  if (matchingIndex >= 0) {
    return [newItem, ...state.recentItems.slice(0, matchingIndex), ...state.recentItems.slice(matchingIndex + 1, 9)];
  } else {
    return [newItem, ...state.recentItems.slice(0, 9)];
  }
};

import ModalsActionTypes from "./modals.types";

const baseModal = {
  open: false,
  context: {},
  actions: {
    refetch: null
  }
};

const INITIAL_STATE = {
  jobLineEdit: { ...baseModal },
  billEnter: { ...baseModal },
  courtesyCarReturn: { ...baseModal },
  noteUpsert: { ...baseModal },
  taskUpsert: { ...baseModal },
  schedule: { ...baseModal },
  partsOrder: { ...baseModal },
  timeTicket: { ...baseModal },
  timeTicketTask: { ...baseModal },
  printCenter: { ...baseModal },
  reconciliation: { ...baseModal },
  payment: { ...baseModal },
  jobCosting: { ...baseModal },
  reportCenter: { ...baseModal },
  partsReceive: { ...baseModal },
  contractFinder: { ...baseModal },
  inventoryUpsert: { ...baseModal },
  ca_bc_eftTableConvert: { ...baseModal },
  cardPayment: { ...baseModal }
};

const modalsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ModalsActionTypes.TOGGLE_MODAL_VISIBLE:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          open: !state[action.payload].open
        }
      };
    case ModalsActionTypes.SET_MODAL_CONTEXT:
      return {
        ...state,
        [action.payload.modal]: {
          ...state[action.payload.modal],
          ...action.payload.context,
          open: true
        }
      };
    default:
      return state;
  }
};

export default modalsReducer;

import { createContext, useContext } from "react";
import { notification } from "antd";

/**
 * Create our NotificationContext to store the `api` object
 * returned by notification.useNotification().
 */
const NotificationContext = createContext(null);

/**
 * A custom hook to make usage easier in child components.
 */
export const useNotification = () => {
  return useContext(NotificationContext);
};

/**
 * The Provider itself:
 *  - Call notification.useNotification() to get [api, contextHolder].
 *  - Render contextHolder somewhere high-level in your app (so the notifications mount properly).
 *  - Provide `api` via the NotificationContext.
 */
export const NotificationProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification({
    placement: "bottomRight",
    bottom: 70,
    showProgress: true
  });

  return (
    <NotificationContext.Provider value={api}>
      {/* contextHolder must be rendered in the DOM so notifications can appear */}
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

import { gql } from "@apollo/client";

export const QUERY_AUDIT_TRAIL = gql`
  query QUERY_AUDIT_TRAIL($jobid: uuid!) {
    audit_trail(where: { jobid: { _eq: $jobid } }, order_by: { created: desc }) {
      useremail
      jobid
      operation
      id
      created
      bodyshopid
    }
    email_audit_trail(where: { jobid: { _eq: $jobid } }, order_by: { created_at: desc }) {
      cc
      contents
      created_at
      id
      jobid
      noteid
      subject
      to
      useremail
      status
    }
  }
`;

export const INSERT_AUDIT_TRAIL = gql`
  mutation INSERT_AUDIT_TRAIL($auditObj: audit_trail_insert_input!) {
    insert_audit_trail_one(object: $auditObj) {
      id
      jobid
      billid
      bodyshopid
      created
      operation
      type
      useremail
    }
  }
`;

import React from "react";
import { Typography } from "antd";
import InstanceRenderMgr from "../../utils/instanceRenderMgr";
import { useTranslation } from "react-i18next";

export default function AboutPage() {
  const { t } = useTranslation();
  return (
    <div style={{ textAlign: "center", margin: "1rem 0rem" }}>
      <Typography.Title level={2}>{`${InstanceRenderMgr({
        imex: t("titles.imexonline"),
        rome: t("titles.romeonline")
      })} V.${import.meta.env.MODE}-${import.meta.env.VITE_APP_GIT_SHA}`}</Typography.Title>
      <Typography.Title level={2}>Third Party Notices</Typography.Title>
      <a href="/3rdparty-app.txt">
        <Typography.Title level={4}>Application</Typography.Title>
      </a>
      <a href="/3rdparty-api.txt">
        <Typography.Title level={4}>API</Typography.Title>
      </a>
    </div>
  );
}

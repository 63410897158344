import { gql } from "@apollo/client";

export const QUERY_SHOP_ASSOCIATIONS = gql`
  query QUERY_SHOP_ASSOCIATIONS($shopid: uuid!) {
    associations(where: { shopid: { _eq: $shopid } }) {
      id
      authlevel
      shopid
      user {
        email
      }
    }
  }
`;

export const UPDATE_ASSOCIATION = gql`
  mutation UPDATE_ASSOCIATION($assocId: uuid!, $assoc: associations_set_input!) {
    update_associations(where: { id: { _eq: $assocId } }, _set: $assoc) {
      returning {
        id
        authlevel
        shopid
        user {
          email
        }
      }
    }
  }
`;

export const INSERT_EULA_ACCEPTANCE = gql`
  mutation INSERT_EULA_ACCEPTANCE($eulaAcceptance: eula_acceptances_insert_input!) {
    insert_eula_acceptances_one(object: $eulaAcceptance) {
      id
    }
  }
`;

export const UPSERT_USER = gql`
  mutation UPSERT_USER($authEmail: String!, $authToken: String!) {
    insert_users(
      objects: [{ email: $authEmail, authid: $authToken }]
      on_conflict: { constraint: users_pkey, update_columns: [authid] }
    ) {
      returning {
        authid
      }
    }
  }
`;

export const UPDATE_DASHBOARD_LAYOUT = gql`
  mutation UPDATE_DASHBOARD_LAYOUT($email: String!, $layout: jsonb!) {
    update_users_by_pk(pk_columns: { email: $email }, _set: { dashboardlayout: $layout }) {
      email
      dashboardlayout
    }
  }
`;

export const UPDATE_FCM_TOKEN = gql`
  mutation UPDATE_FCM_TOKEN($authEmail: String!, $token: jsonb!) {
    update_users(where: { email: { _eq: $authEmail } }, _append: { fcmtokens: $token }) {
      affected_rows
      returning {
        fcmtokens
      }
    }
  }
`;

export const QUERY_KANBAN_SETTINGS = gql`
  query QUERY_KANBAN_SETTINGS($email: String!) {
    associations(where: { _and: { useremail: { _eq: $email }, active: { _eq: true } } }) {
      id
      kanban_settings
    }
  }
`;
export const UPDATE_KANBAN_SETTINGS = gql`
  mutation UPDATE_KANBAN_SETTINGS($id: uuid!, $ks: jsonb) {
    update_associations_by_pk(pk_columns: { id: $id }, _set: { kanban_settings: $ks }) {
      id
      kanban_settings
    }
  }
`;

export const QUERY_NOTIFICATION_SETTINGS = gql`
  query QUERY_NOTIFICATION_SETTINGS($email: String!) {
    associations(where: { _and: { useremail: { _eq: $email }, active: { _eq: true } } }) {
      id
      notification_settings
    }
  }
`;

export const UPDATE_NOTIFICATION_SETTINGS = gql`
  mutation UPDATE_NOTIFICATION_SETTINGS($id: uuid!, $ns: jsonb) {
    update_associations_by_pk(pk_columns: { id: $id }, _set: { notification_settings: $ns }) {
      id
      notification_settings
    }
  }
`;

const MediaActionTypes = {
  SET_MEDIA_FOR_JOB: "SET_MEDIA_FOR_JOB",
  GET_MEDIA_FOR_JOB: "GET_MEDIA_FOR_JOB",
  GET_MEDIA_FOR_JOB_ERROR: "GET_MEDIA_FOR_JOB_ERROR",
  ADD_MEDIA_FOR_JOB: "ADD_MEDIA_FOR_JOB",
  TOGGLE_MEDIA_SELECTED: "TOGGLE_MEDIA_SELECTED",
  GET_MEDIA_FOR_BILL: "GET_MEDIA_FOR_BILL",
  SELECT_ALL_MEDIA_FOR_JOB: "SELECT_ALL_MEDIA_FOR_JOB",
  DESELECT_ALL_MEDIA_FOR_JOB: "DESELECT_ALL_MEDIA_FOR_JOB"
};
export default MediaActionTypes;

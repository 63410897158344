import { gql } from "@apollo/client";

export const INTROSPECTION = gql`
  query INTROSPECTION {
    __schema {
      types {
        name
      }
    }
  }
`;

export const QUERY_EULA = gql`
  query QUERY_EULA($now: timestamptz!) {
    eulas(
      where: { effective_date: { _lte: $now }, _or: [{ end_date: { _is_null: true } }, { end_date: { _gt: $now } }] }
    ) {
      id
      content

      eula_acceptances {
        id
        date_accepted
      }
    }
  }
`;

export const QUERY_BODYSHOP = gql`
  query QUERY_BODYSHOP {
    bodyshops(where: { associations: { active: { _eq: true } } }) {
      associations {
        id
        authlevel
        useremail
        default_prod_list_view
        user {
          authid
          email
          dashboardlayout
          validemail
          employee {
            id
          }
        }
      }
      address1
      address2
      city
      country
      created_at
      email
      phone
      federal_tax_id
      id
      insurance_vendor_id
      logo_img_path
      md_ro_statuses
      md_order_statuses
      md_functionality_toggles
      shopname
      state
      state_tax_id
      updated_at
      zip_post
      shoprates
      region_config
      md_responsibility_centers
      messagingservicesid
      template_header
      textid
      production_config
      bill_tax_rates
      inhousevendorid
      accountingconfig
      appt_length
      stripe_acct_id
      ssbuckets
      scoreboard_target
      md_referral_sources
      md_messaging_presets
      intakechecklist
      speedprint
      md_parts_locations
      md_notes_presets
      md_rbac
      prodtargethrs
      md_classes
      md_ins_cos
      md_categories
      enforce_class
      md_labor_rates
      deliverchecklist
      target_touchtime
      appt_colors
      appt_alt_transport
      schedule_start_time
      schedule_end_time
      imexshopid
      default_adjustment_rate
      workingdays
      use_fippa
      md_payment_types
      md_hour_split
      sub_status
      jobsizelimit
      md_ccc_rates
      enforce_referral
      website
      jc_hourly_rates
      md_jobline_presets
      cdk_dealerid
      features
      attach_pdf_to_email
      tt_allow_post_to_invoiced
      cdk_configuration
      md_estimators
      md_ded_notes
      pbs_configuration
      pbs_serialnumber
      md_filehandlers
      md_email_cc
      timezone
      ss_configuration
      md_from_emails
      last_name_first
      md_parts_order_comment
      bill_allow_post_to_closed
      md_to_emails
      uselocalmediaserver
      localmediaserverhttp
      localmediaservernetwork
      localmediatoken
      enforce_conversion_csr
      md_lost_sale_reasons
      md_parts_scan
      enforce_conversion_category
      tt_enforce_hours_for_tech_console
      md_tasks_presets
      use_paint_scale_data
      intellipay_config
      md_ro_guard
      employee_teams(order_by: { name: asc }, where: { active: { _eq: true } }) {
        id
        name
        employee_team_members {
          id
          employeeid
          labor_rates
          percentage
        }
      }
      employees {
        user_email
        id
        active
        first_name
        last_name
        employee_number
        rates
        external_id
        flat_rate
      }
    }
  }
`;

export const QUERY_SHOP_ID = gql`
  query QUERY_SHOP_ID {
    bodyshops(where: { associations: { active: { _eq: true } } }) {
      id
    }
  }
`;

export const UPDATE_SHOP = gql`
  mutation UPDATE_SHOP($id: uuid, $shop: bodyshops_set_input!) {
    update_bodyshops(where: { id: { _eq: $id } }, _set: $shop) {
      returning {
        address1
        address2
        city
        country
        created_at
        email
        phone
        federal_tax_id
        id
        insurance_vendor_id
        logo_img_path
        md_ro_statuses
        md_order_statuses
        md_functionality_toggles
        shopname
        state
        state_tax_id
        updated_at
        zip_post
        shoprates
        region_config
        md_responsibility_centers
        messagingservicesid
        template_header
        textid
        production_config
        bill_tax_rates
        appt_length
        stripe_acct_id
        ssbuckets
        scoreboard_target
        md_referral_sources
        md_messaging_presets
        intakechecklist
        speedprint
        md_parts_locations
        md_notes_presets
        md_rbac
        prodtargethrs
        md_classes
        md_ins_cos
        md_categories
        enforce_class
        md_labor_rates
        deliverchecklist
        target_touchtime
        appt_colors
        appt_alt_transport
        schedule_start_time
        schedule_end_time
        imexshopid
        default_adjustment_rate
        workingdays
        use_fippa
        md_payment_types
        md_hour_split
        sub_status
        jobsizelimit
        md_ccc_rates
        enforce_referral
        website
        jc_hourly_rates
        md_jobline_presets
        cdk_dealerid
        attach_pdf_to_email
        tt_allow_post_to_invoiced
        cdk_configuration
        md_estimators
        md_ded_notes
        pbs_configuration
        pbs_serialnumber
        md_filehandlers
        md_email_cc
        timezone
        ss_configuration
        md_from_emails
        last_name_first
        md_parts_order_comment
        bill_allow_post_to_closed
        md_to_emails
        uselocalmediaserver
        localmediaserverhttp
        localmediaservernetwork
        localmediatoken
        enforce_conversion_csr
        md_lost_sale_reasons
        md_parts_scan
        enforce_conversion_category
        tt_enforce_hours_for_tech_console
        md_tasks_presets
        intellipay_config
        md_ro_guard
        employee_teams(order_by: { name: asc }, where: { active: { _eq: true } }) {
          id
          name
          employee_team_members {
            id
            employeeid
            labor_rates
            percentage
          }
        }
        employees {
          id
          first_name
          active
          last_name
          employee_number
          rates
          user_email
          external_id
        }
      }
    }
  }
`;
export const QUERY_INTAKE_CHECKLIST = gql`
  query QUERY_INTAKE_CHECKLIST($shopId: uuid!, $jobId: uuid!) {
    bodyshops_by_pk(id: $shopId) {
      id
      intakechecklist
    }
    jobs_by_pk(id: $jobId) {
      id
      ro_number
      production_vars
      scheduled_completion
      scheduled_delivery
      intakechecklist
      status
      owner {
        allow_text_message
        id
      }
      labhrs: joblines_aggregate(where: { _and: [{ mod_lbr_ty: { _neq: "LAR" } }, { removed: { _eq: false } }] }) {
        aggregate {
          sum {
            mod_lb_hrs
          }
        }
      }
      larhrs: joblines_aggregate(where: { _and: [{ mod_lbr_ty: { _eq: "LAR" } }, { removed: { _eq: false } }] }) {
        aggregate {
          sum {
            mod_lb_hrs
          }
        }
      }
    }
  }
`;
export const QUERY_DELIVER_CHECKLIST = gql`
  query QUERY_DELIVER_CHECKLIST($shopId: uuid!, $jobId: uuid!) {
    bodyshops_by_pk(id: $shopId) {
      id
      deliverchecklist
    }
    jobs_by_pk(id: $jobId) {
      id
      ro_number
      actual_completion
      actual_delivery
    }
  }
`;
export const QUERY_STRIPE_ID = gql`
  query QUERY_STRIPE_ID {
    bodyshops(where: { associations: { active: { _eq: true } } }) {
      stripe_acct_id
    }
  }
`;

export const GET_ACTIVE_EMPLOYEES_IN_SHOP = gql`
  query GetActiveEmployeesInShop($shopid: uuid!) {
    associations(where: { shopid: { _eq: $shopid } }) {
      id
      useremail
      shopid
    }
  }
`;

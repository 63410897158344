import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectBodyshop } from "../../redux/user/user.selectors";
import dayjs from "../../utils/day";
import InstanceRenderManager from "../../utils/instanceRenderMgr";
import AlertComponent from "../alert/alert.component";
import { ValidateFeatureName } from "./blur-wrapper.component";

const mapStateToProps = createStructuredSelector({
  bodyshop: selectBodyshop
});

function FeatureWrapper({
  bodyshop,
  featureName,
  noauth,
  blurContent,
  children,
  upsellComponent,
  bypass,
  ...restProps
}) {
  const { t } = useTranslation();

  if (bypass) {
    if (import.meta.env.DEV) {
      console.trace("*** Feature Wrapper BYPASS USED", featureName);
    }
    return children;
  }

  if (import.meta.env.DEV) {
    if (!ValidateFeatureName(featureName)) console.trace("*** INVALID FEATURE NAME", featureName);
  }

  if (upsellComponent) {
    console.error("*** Upsell component passed in. This is not yet implemented.");
  }

  if (HasFeatureAccess({ featureName, bodyshop })) return children;

  if (blurContent) {
    const childrenWithBlurProps = React.Children.map(children, (child) => {
      // Checking isValidElement is the safe way and avoids a
      // typescript error too.
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { blur: true });
      }
      return child;
    });
    return childrenWithBlurProps;
  } else {
    return (
      noauth || (
        <AlertComponent
          message={t("general.messages.nofeatureaccess", {
            app: InstanceRenderManager({
              imex: "$t(titles.imexonline)",
              rome: "$t(titles.romeonline)"
            })
          })}
          type="warning"
        />
      )
    );
  }
}

export function HasFeatureAccess({ featureName, bodyshop, bypass, debug = false }) {
  if (debug) {
    console.trace(`*** HasFeatureAccessFunction called with feature << ${featureName} >>`);
  }
  if (bypass) {
    if (import.meta.env.DEV) {
      console.trace("*** Feature Wrapper BYPASS USED", featureName);
    }
    return true;
  }
  return bodyshop?.features?.allAccess || dayjs(bodyshop?.features[featureName]).isAfter(dayjs());
}

export default connect(mapStateToProps, null)(FeatureWrapper);

import Icon, { CarOutlined, ScheduleOutlined, SearchOutlined, UserAddOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaBusinessTime } from "react-icons/fa";
import { FiLogIn, FiLogOut } from "react-icons/fi";
import { MdTimer } from "react-icons/md";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { techLogout } from "../../redux/tech/tech.actions";
import { selectTechnician } from "../../redux/tech/tech.selectors";
import { BsKanban } from "react-icons/bs";
import { useSplitTreatments } from "@splitsoftware/splitio-react";
import { selectBodyshop } from "../../redux/user/user.selectors";
import { setModalContext } from "../../redux/modals/modals.actions";

const { Sider } = Layout;

const mapStateToProps = createStructuredSelector({
  technician: selectTechnician,
  bodyshop: selectBodyshop
});
const mapDispatchToProps = (dispatch) => ({
  techLogout: () => dispatch(techLogout()),
  setTimeTicketTaskContext: (context) => dispatch(setModalContext({ context: context, modal: "timeTicketTask" }))
});

export function TechSider({ technician, techLogout, bodyshop, setTimeTicketTaskContext }) {
  const [collapsed, setCollapsed] = useState(true);
  const { t } = useTranslation();
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const {
    treatments: { Enhanced_Payroll }
  } = useSplitTreatments({
    attributes: {},
    names: ["Enhanced_Payroll"],
    splitKey: bodyshop.imexshopid
  });

  const items = [
    {
      key: "1",
      icon: <Icon component={FiLogIn} />,
      disabled: !!technician,
      label: <Link to={`/tech/login`}>{t("menus.tech.login")}</Link>
    },
    {
      key: "2",
      icon: <SearchOutlined />,
      disabled: !!!technician,
      label: <Link to={`/tech/joblookup`}>{t("menus.tech.joblookup")}</Link>
    }
  ];

  if (Enhanced_Payroll.treatment === "on") {
    items.push(
      {
        key: "TechAssignedProdJobs",
        disabled: !!!technician,
        icon: <UserAddOutlined />,
        label: <Link to={`/tech/assigned`}> {t("menus.tech.assignedjobs")}</Link>
      },
      {
        key: "3",
        disabled: !!!technician,
        icon: <Icon component={FaBusinessTime} />,
        onClick: () => {
          setTimeTicketTaskContext({
            actions: {},
            context: { jobid: null }
          });
        },
        label: t("menus.tech.claimtask")
      }
    );
  } else {
    items.push({
      key: "3",
      disabled: !!!technician,
      icon: <Icon component={FaBusinessTime} />,
      label: <Link to={`/tech/jobclock`}>{t("menus.tech.jobclockin")}</Link>
    });
  }

  items.push(
    {
      key: "4",
      icon: <Icon component={MdTimer} />,
      disabled: !!!technician,
      label: <Link to={`/tech/shiftclock`}>{t("menus.tech.shiftclockin")}</Link>
    },
    {
      key: "dispatchedparts",
      disabled: !!!technician,
      icon: <CarOutlined />,
      label: <Link to={`/tech/dispatchedparts`}>{t("menus.tech.dispatchedparts")}</Link>
    },
    {
      key: "5",
      icon: <ScheduleOutlined />,
      disabled: !!!technician,
      label: <Link to={`/tech/list`}>{t("menus.tech.productionlist")}</Link>
    },
    {
      key: "6",
      icon: <Icon component={BsKanban} />,
      disabled: !!!technician,
      label: <Link to={`/tech/board`}>{t("menus.tech.productionboard")}</Link>
    },
    {
      key: "7",
      icon: <Icon component={FiLogOut} />,
      disabled: !!!technician,
      label: t("menus.tech.logout")
    }
  );

  return (
    <Sider
      style={{
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0
      }}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <Menu
        theme="dark"
        defaultSelectedKeys={["1"]}
        mode="inline"
        onClick={(e) => {
          if (e.key === "7") {
            techLogout();
          }
        }}
        items={items}
      />
    </Sider>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TechSider);

import MediaActionTypes from "./media.types";

const INITIAL_STATE = { error: null };

const mediaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MediaActionTypes.SET_MEDIA_FOR_JOB:
      return { ...state, [action.payload.jobid]: action.payload.media };
    case MediaActionTypes.GET_MEDIA_FOR_JOB_ERROR:
      return { ...state, error: action.payload };
    case MediaActionTypes.ADD_MEDIA_FOR_JOB:
      return {
        ...state,
        [action.payload.jobid]: [
          ...(state[action.payload.jobid] ? state[action.payload.jobid] : []),
          ...(action.payload.media || [])
        ]
      };
    case MediaActionTypes.TOGGLE_MEDIA_SELECTED:
      return {
        ...state,
        [action.payload.jobid]: state[action.payload.jobid].map((p) => {
          if (p.filename === action.payload.filename) {
            p.isSelected = !p.isSelected;
          }
          return p;
        })
      };
    case MediaActionTypes.SELECT_ALL_MEDIA_FOR_JOB:
      return {
        ...state,
        [action.payload.jobid]: state[action.payload.jobid].map((p) => {
          p.isSelected = true;

          return p;
        })
      };
    case MediaActionTypes.DESELECT_ALL_MEDIA_FOR_JOB:
      return {
        ...state,
        [action.payload.jobid]: state[action.payload.jobid].map((p) => {
          p.isSelected = false;
          return p;
        })
      };
    default:
      return state;
  }
};

export default mediaReducer;

import { defaultsDeep } from "lodash";
import { theme } from "antd";
import InstanceRenderMgr from "../utils/instanceRenderMgr";

const { defaultAlgorithm, darkAlgorithm } = theme;

let isDarkMode = false;

/**
 * Default theme
 * @type {{components: {Menu: {itemDividerBorderColor: string}}}}
 */
const defaultTheme = {
  components: {
    Table: {
      rowHoverBg: "#e7f3ff",
      rowSelectedBg: "#e6f7ff",
      headerSortHoverBg: "transparent"
    },
    Menu: {
      darkItemHoverBg: "#1890ff",
      itemHoverBg: "#1890ff",
      horizontalItemHoverBg: "#1890ff"
    }
  },
  token: {
    colorPrimary: InstanceRenderMgr({
      imex: "#1890ff",
      rome: "#326ade"
    }),
    colorInfo: InstanceRenderMgr({
      imex: "#1890ff",
      rome: "#326ade"
    })
  }
};

/**
 * Development theme
 * @type {{components: {Menu: {itemHoverBg: string, darkItemHoverBg: string, horizontalItemHoverBg: string}}, token: {colorPrimary: string}}}
 */
const devTheme = {
  components: {
    Menu: {
      darkItemHoverBg: "#a51d1d",
      itemHoverBg: "#a51d1d",
      horizontalItemHoverBg: "#a51d1d"
    }
  },
  token: {
    colorPrimary: "#a51d1d"
  }
};

/**
 * Production theme
 * @type {{components: {Menu: {itemHoverBg: string, darkItemHoverBg: string, horizontalItemHoverBg: string}}, token: {colorPrimary: string}}}
 */
const prodTheme = {};

const currentTheme = import.meta.env.DEV ? devTheme : prodTheme;

const finaltheme = {
  algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
  ...defaultsDeep(currentTheme, defaultTheme)
};
export default finaltheme;

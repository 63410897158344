import { gql } from "@apollo/client";

export const QUERY_ALL_ACTIVE_APPOINTMENTS = gql`
  query QUERY_ALL_ACTIVE_APPOINTMENTS($start: timestamptz!, $end: timestamptz!, $startd: date!, $endd: date!) {
    employee_vacation(
      where: {
        _or: [
          { start: { _gte: $startd } }
          { end: { _lte: $endd } }
          { _and: [{ start: { _lte: $startd } }, { end: { _gte: $endd } }] }
        ]
      }
    ) {
      id
      start
      end
      employee {
        id
        last_name
        first_name
      }
    }
    appointments(where: { canceled: { _eq: false }, end: { _lte: $end }, start: { _gte: $start } }) {
      start
      id
      end
      arrived
      title
      isintake
      block
      color
      note
      job {
        alt_transport
        ro_number
        ownr_ln
        ownr_co_nm
        ownr_fn
        ownr_ph1
        ownr_ph2
        ownr_ea
        clm_total
        id
        clm_no
        ins_co_nm
        v_model_yr
        v_make_desc
        v_model_desc
        est_ct_fn
        est_ct_ln
        comment
        loss_of_use
        labhrs: joblines_aggregate(where: { mod_lbr_ty: { _neq: "LAR" }, removed: { _eq: false } }) {
          aggregate {
            sum {
              mod_lb_hrs
            }
          }
        }
        larhrs: joblines_aggregate(where: { mod_lbr_ty: { _eq: "LAR" }, removed: { _eq: false } }) {
          aggregate {
            sum {
              mod_lb_hrs
            }
          }
        }
      }
    }
  }
`;

export const INSERT_APPOINTMENT_BLOCK = gql`
  mutation INSERT_APPOINTMENT_BLOCK($app: [appointments_insert_input!]!) {
    insert_appointments(objects: $app) {
      returning {
        id
        start
        end
        arrived
        title
        isintake
        block
        note
      }
    }
  }
`;

export const INSERT_MANUAL_APPT = gql`
  mutation INSERT_MANUAL_APPT($apt: appointments_insert_input!) {
    insert_appointments_one(object: $apt) {
      start
      id
      end
      arrived
      title
      isintake
      block
      color
      note
      canceled
      job {
        id
      }
    }
  }
`;

export const INSERT_APPOINTMENT = gql`
  mutation INSERT_APPOINTMENT($app: [appointments_insert_input!]!, $jobId: uuid, $altTransport: String) {
    insert_appointments(objects: $app) {
      returning {
        id
        start
        end
        arrived
        title
        isintake
        block
        color
        note
      }
    }
    update_jobs(where: { id: { _eq: $jobId } }, _set: { alt_transport: $altTransport }) {
      returning {
        id
        alt_transport
      }
    }
  }
`;

export const QUERY_APPOINTMENT_BY_DATE = gql`
  query QUERY_APPOINTMENT_BY_DATE($start: timestamptz, $end: timestamptz, $startd: date!, $endd: date!) {
    employee_vacation(where: { _or: [{ start: { _gte: $startd } }, { end: { _lte: $endd } }] }) {
      id
      start
      end
      employee {
        id
        last_name
        first_name
      }
    }
    appointments(where: { start: { _lte: $end, _gte: $start }, canceled: { _eq: false } }) {
      start
      id
      end
      title
      isintake
      block
      color
      note
      job {
        alt_transport
        ro_number
        ownr_ln
        ownr_fn
        ownr_ph1
        ownr_ph2
        ownr_ea
        clm_total
        id
        clm_no
        vehicle {
          id
          v_model_yr
          v_make_desc
          v_model_desc
        }
        labhrs: joblines_aggregate(where: { mod_lbr_ty: { _neq: "LAR" }, removed: { _eq: false } }) {
          aggregate {
            sum {
              mod_lb_hrs
            }
          }
        }
        larhrs: joblines_aggregate(where: { mod_lbr_ty: { _eq: "LAR" }, removed: { _eq: false } }) {
          aggregate {
            sum {
              mod_lb_hrs
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_APPOINTMENT = gql`
  mutation UPDATE_APPOINTMENT($appid: uuid!, $app: appointments_set_input) {
    update_appointments(where: { id: { _eq: $appid } }, _set: $app) {
      returning {
        id
        start
        id
        end
        arrived
        title
        isintake
        block
        color
        note
      }
    }
  }
`;

export const CANCEL_APPOINTMENT_BY_ID = gql`
  mutation CANCEL_APPOINTMENT_BY_ID($appid: uuid!) {
    update_appointments(where: { id: { _eq: $appid } }, _set: { canceled: true }) {
      returning {
        id
        canceled
      }
    }
  }
`;

export const CANCEL_APPOINTMENTS_BY_JOB_ID = gql`
  mutation CANCEL_APPOINTMENTS_BY_JOB_ID($jobid: uuid!, $job: jobs_set_input) {
    update_appointments(
      where: { _and: { jobid: { _eq: $jobid }, arrived: { _eq: false } } }
      _set: { canceled: true }
    ) {
      returning {
        id
        canceled
      }
    }
    update_jobs_by_pk(pk_columns: { id: $jobid }, _set: $job) {
      date_scheduled
      id
      scheduled_in
      scheduled_completion
      status
      lost_sale_reason
      date_lost_sale
    }
  }
`;

export const QUERY_APPOINTMENTS_BY_JOBID = gql`
  query QUERY_APPOINTMENTS_BY_JOBID($jobid: uuid!) {
    appointments(where: { jobid: { _eq: $jobid } }, order_by: { start: desc }) {
      start
      id
      end
      color
      isintake
      arrived
      canceled
      created_at
      block
      note
    }
  }
`;
export const QUERY_SCHEDULE_LOAD_DATA = gql`
  query QUERY_SCHEDULE_LOAD_DATA($start: timestamptz!, $end: timestamptz!) {
    prodJobs: jobs(where: { inproduction: { _eq: true }, suspended: { _eq: false } }) {
      id
      actual_in
      scheduled_in
      actual_completion
      scheduled_completion
      inproduction
      ro_number
      labhrs: joblines_aggregate(where: { mod_lbr_ty: { _neq: "LAR" }, removed: { _eq: false } }) {
        aggregate {
          sum {
            mod_lb_hrs
          }
        }
      }
      larhrs: joblines_aggregate(where: { mod_lbr_ty: { _eq: "LAR" }, removed: { _eq: false } }) {
        aggregate {
          sum {
            mod_lb_hrs
          }
        }
      }
    }
    compJobs: jobs(
      where: {
        _and: [
          { suspended: { _eq: false } }
          {
            _or: [
              { scheduled_completion: { _gte: $start, _lte: $end } }
              { actual_completion: { _gte: $start, _lte: $end } }
            ]
          }
        ]
      }
    ) {
      id
      status
      ro_number
      scheduled_completion
      actual_completion
      scheduled_in
      ownr_fn
      ownr_ln
      ownr_co_nm
      inproduction
      labhrs: joblines_aggregate(where: { mod_lbr_ty: { _neq: "LAR" }, removed: { _eq: false } }) {
        aggregate {
          sum {
            mod_lb_hrs
          }
        }
      }
      larhrs: joblines_aggregate(where: { mod_lbr_ty: { _eq: "LAR" }, removed: { _eq: false } }) {
        aggregate {
          sum {
            mod_lb_hrs
          }
        }
      }
    }
    arrJobs: jobs(where: { scheduled_in: { _gte: $start, _lte: $end }, suspended: { _eq: false } }) {
      id
      scheduled_in
      actual_in
      scheduled_completion
      ro_number
      ownr_fn
      ownr_ln
      ownr_co_nm
      alt_transport
      actual_completion
      inproduction
      status
      labhrs: joblines_aggregate(where: { mod_lbr_ty: { _neq: "LAR" }, removed: { _eq: false } }) {
        aggregate {
          sum {
            mod_lb_hrs
          }
        }
      }
      larhrs: joblines_aggregate(where: { mod_lbr_ty: { _eq: "LAR" }, removed: { _eq: false } }) {
        aggregate {
          sum {
            mod_lb_hrs
          }
        }
      }
    }
  }
`;

export const MARK_APPOINTMENT_ARRIVED = gql`
  mutation MARK_APPOINTMENT_ARRIVED($appointmentId: uuid!) {
    update_appointments(where: { id: { _eq: $appointmentId } }, _set: { arrived: true }) {
      affected_rows
      returning {
        id
        arrived
      }
    }
  }
`;
export const MARK_LATEST_APPOINTMENT_ARRIVED = gql`
  mutation MARK_LATEST_APPOINTMENT_ARRIVED($jobId: uuid!) {
    update_appointments(
      where: { jobid: { _eq: $jobId }, canceled: { _eq: false }, isintake: { _eq: true } }
      _set: { arrived: true }
    ) {
      affected_rows
      returning {
        id
        arrived
      }
    }
  }
`;

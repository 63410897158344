/**
 * FRONT-END Returns the corresponding component/function/object based on the type of instance.
 * Based on the node env params, return the correct prop.
 * Default is to return the ImEX Prop
 * @typedef {Object} InstanceManagerObject
 * @property { string | object | function } executeFunction  will try to execute the function instead of returning a value.
 * @property { string | object | function } rome  Return this prop if Rome.
 * @property { string | object | function } proman Return this prop if Rome.
 * @property { string | object | function } imex  Return this prop if Rome.
 */

export default function InstanceRenderManager({ executeFunction, rome, promanager, imex, debug, instance, args }) {
  let propToReturn = null;

  //TODO: Remove after debugging.
  if (promanager) {
    console.trace("ProManager Prop was used");
  }

  switch (instance || import.meta.env.VITE_APP_INSTANCE) {
    case "IMEX":
      propToReturn = imex;
      break;
    case "ROME":
      if (rome === "USE_IMEX") {
        propToReturn = imex;
      } else {
        propToReturn = rome;
      }
      break;
    case "PROMANAGER":
      //Return the rome prop if USE_ROME.
      //If not USE_ROME, we want to default back to the rome prop if it's undefined.
      //If null, we might want to show nothing, so make sure we return null.
      propToReturn = promanager === "USE_ROME" ? rome : promanager !== undefined ? promanager : rome;
      break;
    default:
      propToReturn = imex;
      break;
  }

  if (debug) {
    console.log("InstanceRenderManager Debugger");
    console.log("=========================");
    console.log({ executeFunction, rome, promanager, imex, debug, propToReturn });
    console.log("=========================");
  }

  //Checking to see if we need to default to another one.
  if (propToReturn === "imex") {
    propToReturn = imex;
  }
  if (executeFunction && typeof propToReturn === "function") return propToReturn(...args);
  return propToReturn === undefined ? null : propToReturn;
}

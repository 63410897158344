import { all } from "redux-saga/effects";
// import { sendEmailFailure, sendEmailSuccess } from "./email.actions";
// import { renderTemplate } from "../application/application.actions";
// import EmailActionTypes from "./email.types";
// import axios from "axios";

// export function* onSendEmail() {
//   yield takeLatest(EmailActionTypes.SEND_EMAIL, sendEmail);
// }
// export function* sendEmail(payload) {
//   try {
//     console.log("Sending thta email", payload);
//     axios.post("/sendemail", payload).then(response => {
//       console.log(JSON.stringify(response));
//       put(sendEmailSuccess());
//     });
//   } catch (error) {
//     console.log("Error in sendEmail saga.");
//     yield put(sendEmailFailure(error.message));
//   }
// }

// export function* onSendEmailSuccess() {
//   yield takeLatest(EmailActionTypes.SEND_EMAIL_SUCCESS, sendEmailSuccessSaga);
// }
// export function* sendEmailSuccessSaga() {
//   try {
//     console.log("Send email success.");
//   } catch (error) {
//     console.log("Error in sendEmailSuccess saga.");
//     yield put(sendEmailFailure(error.message));
//   }
// }

// export function* onSendEmailFailure() {
//   yield takeLatest(EmailActionTypes.SEND_EMAIL_FAILURE, sendEmailFailureSaga);
// }
// export function* sendEmailFailureSaga(error) {
//   try {
//     yield console.log(error);
//   } catch (error) {
//     console.log("Error in sendEmailFailure saga.", error.message);
//   }
//}

// export function* onSetEmailOptions() {
//   yield takeLatest(EmailActionTypes.SET_EMAIL_OPTIONS, setEmailOptions);
// }
// export function* setEmailOptions({ payload: { template } }) {
//   console.log("function*setEmailOptions -> template", template);

//   try {
//     yield put(renderTemplate(template));
//   } catch (error) {
//     console.log("Error in setEmailOptions saga.", error.message);
//   }
// }

export function* emailSagas() {
  yield all([
    // call(onSendEmail),
    // call(onSendEmailFailure),
    // call(onSendEmailSuccess)
    //call(onSetEmailOptions),
  ]);
}

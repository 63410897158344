import {
  AppstoreAddOutlined,
  BuildOutlined,
  CalendarOutlined,
  CarOutlined,
  DashboardOutlined,
  DollarOutlined,
  LineChartOutlined,
  MobileOutlined,
  StarOutlined
} from "@ant-design/icons";
import { Button, Card, Result } from "antd";
import i18n from "i18next";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { store } from "../../redux/store.js";
import InstanceRenderManager from "../../utils/instanceRenderMgr.js";
import "./upsell.styles.scss";

export default function UpsellComponent({ featureName, subFeatureName, upsell, disableMask }) {
  const { t } = useTranslation();
  const resultProps = upsell || upsellEnum[featureName][subFeatureName];

  const componentRef = useRef(null);

  useEffect(() => {
    if (disableMask) return;
    const parentElement = componentRef.current?.parentElement;
    if (parentElement) {
      const mask = document.createElement("div");
      mask.style.position = "absolute";
      mask.style.top = 0;
      mask.style.left = 0;
      mask.style.width = "100%";
      mask.style.height = "100%";
      mask.style.backgroundColor = "rgba(0, 0, 0, 0.05)";
      // mask.style.zIndex = 9999;
      parentElement.style.position = "relative";

      parentElement.prepend(mask);

      return () => {
        parentElement.removeChild(mask);
      };
    }
  }, [disableMask]);

  if (!resultProps) return <Result status="info" title={t("upsell.messages.generic")} />;
  return (
    <div ref={componentRef}>
      <Result status="info" icon={<AppstoreAddOutlined />} {...resultProps} />
    </div>
  );
}
//Kept in the same function as the result props line must mirror and doesnt warrant a separate function.
export function UpsellMaskWrapper({ children, upsell, featureName, subFeatureName }) {
  const resultProps = upsell || upsellEnum[featureName][subFeatureName];
  return (
    <div className="mask-wrapper">
      <div className="mask-content">{children}</div>
      <div className="mask-overlay">
        <Card size="small">
          <Result status="info" icon={<AppstoreAddOutlined />} {...resultProps} />
        </Card>
      </div>
    </div>
  );
}

//This is kept in this function as pulling it out into it's own util/enum prevents passing JSX as an `extra` prop
export const upsellEnum = () => {
  const { currentUser, bodyshop } = store.getState().user;

  const [first_name, ...last_name] = currentUser?.displayName ? currentUser.displayName.split(" ") : [];
  const LearnMoreLink = encodeURI(
    InstanceRenderManager({
      imex: `https://imexsystems.ca/schedule-a-demo/`,
      rome: `https://forms.zohopublic.com/rometech/form/ROLearnMore/formperma/0G29z8LgLlvKK8nno-b7s-GHgNXwIFlrMeE0mC394L4?first_name=${first_name || ""}&last_name=${last_name.join(" ") || ""}&shop_name=${bodyshop?.shopname || ""}&email=${currentUser?.email || ""}&shop_phone=${bodyshop?.phone || ""}`
    })
  );

  return {
    bills: {
      autoreconcile: {
        //icon: null,
        title: i18n.t("upsell.messages.bills.autoreconcile.title"),
        subTitle: i18n.t("upsell.messages.bills.autoreconcile.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
        //status: null
      },
      general: {
        //icon: null,
        title: i18n.t("upsell.messages.bills.general.title"),
        subTitle: i18n.t("upsell.messages.bills.general.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
        //status: null
      }
    },
    checklist: {
      general: {
        //icon: null,
        title: i18n.t("upsell.messages.checklist.general.title"),
        subTitle: i18n.t("upsell.messages.checklist.general.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
        //status: null
      }
    },
    payments: {
      general: {
        //icon: null,
        title: i18n.t("upsell.messages.payments.general.title"),
        subTitle: i18n.t("upsell.messages.payments.general.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
        //status: null
      }
    },
    audit: {
      general: {
        //icon: null,
        title: i18n.t("upsell.messages.audit.general.title"),
        subTitle: i18n.t("upsell.messages.audit.general.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
        //status: null
      }
    },
    lifecycle: {
      general: {
        //icon: null,
        title: i18n.t("upsell.messages.lifecycle.general.title"),
        subTitle: i18n.t("upsell.messages.lifecycle.general.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
        //status: null }
      }
    },
    media: {
      general: {
        //icon: null,
        title: i18n.t("upsell.messages.media.general.title"),
        subTitle: i18n.t("upsell.messages.media.general.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
        //status: null }
      },
      mobile: {
        icon: <MobileOutlined />,
        title: i18n.t("upsell.messages.media.mobile.title"),
        subTitle: i18n.t("upsell.messages.media.mobile.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
        //status: null }
      }
    },
    timetickets: {
      allocations: {
        title: i18n.t("upsell.messages.timetickets.allocations.title"),
        subTitle: i18n.t("upsell.messages.timetickets.allocations.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
      },
      general: {
        title: i18n.t("upsell.messages.timetickets.general.title"),
        subTitle: i18n.t("upsell.messages.timetickets.general.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
      }
    },
    smartscheduling: {
      general: {
        icon: <CalendarOutlined />,
        title: i18n.t("upsell.messages.smartscheduling.general.title"),
        subTitle: i18n.t("upsell.messages.smartscheduling.general.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
      },
      hrsdelta: {
        icon: <CarOutlined />,
        title: i18n.t("upsell.messages.smartscheduling.hrsdelta.title"),
        subTitle: i18n.t("upsell.messages.smartscheduling.hrsdelta.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
      },
      datepicker: {
        icon: <CarOutlined />,
        title: i18n.t("upsell.messages.smartscheduling.datepicker.title"),
        subTitle: i18n.t("upsell.messages.smartscheduling.datepicker.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
      }
    },
    accounting: {
      payables: {
        icon: <DollarOutlined />,
        title: i18n.t("upsell.messages.accounting.payables.title"),
        subTitle: i18n.t("upsell.messages.accounting.payables.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
      },
      receivables: {
        icon: <DollarOutlined />,
        title: i18n.t("upsell.messages.accounting.receivables.title"),
        subTitle: i18n.t("upsell.messages.accounting.receivables.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
      },
      payments: {
        icon: <DollarOutlined />,
        title: i18n.t("upsell.messages.accounting.payments.title"),
        subTitle: i18n.t("upsell.messages.accounting.payments.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
      }
    },
    courtesycars: {
      general: {
        icon: <CarOutlined />,
        title: i18n.t("upsell.messages.courtesycars.general.title"),
        subTitle: i18n.t("upsell.messages.courtesycars.general.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
      }
    },
    dashboard: {
      general: {
        icon: <DashboardOutlined />,
        title: i18n.t("upsell.messages.dashboard.general.title"),
        subTitle: i18n.t("upsell.messages.dashboard.general.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
      }
    },
    visualboard: {
      general: {
        icon: <BuildOutlined />,
        title: i18n.t("upsell.messages.visualboard.general.title"),
        subTitle: i18n.t("upsell.messages.visualboard.general.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
      }
    },
    scoreboard: {
      general: {
        icon: <LineChartOutlined />,
        title: i18n.t("upsell.messages.scoreboard.general.title"),
        subTitle: i18n.t("upsell.messages.scoreboard.general.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
      }
    },
    techconsole: {
      general: {
        icon: <LineChartOutlined />,
        title: i18n.t("upsell.messages.techconsole.general.title"),
        subTitle: i18n.t("upsell.messages.techconsole.general.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
      }
    },
    csi: {
      general: {
        icon: <StarOutlined />,
        title: i18n.t("upsell.messages.csi.general.title"),
        subTitle: i18n.t("upsell.messages.csi.general.subtitle"),
        extra: (
          <Link to={LearnMoreLink} target="_blank">
            <Button type="primary">{i18n.t("upsell.cta.learnmore")}</Button>
          </Link>
        )
      }
    }
  };
};

import TechActionTypes from "./tech.types";

export const techLoginStart = ({ employeeid, pin }) => ({
  type: TechActionTypes.TECH_LOGIN_START,
  payload: { employeeid, pin }
});

export const techLoginSuccess = (tech) => ({
  type: TechActionTypes.TECH_LOGIN_SUCCESS,
  payload: tech
});

export const techLoginFailure = (error) => ({
  type: TechActionTypes.TECH_LOGIN_FAILURE,
  payload: error
});

export const techLogout = () => ({
  type: TechActionTypes.TECH_LOGOUT
});

//import "tui-image-editor/dist/tui-image-editor.css";
import { Result } from "antd";
import * as markerjs2 from "markerjs2";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectBodyshop, selectCurrentUser } from "../../redux/user/user.selectors";
import { handleUpload } from "../documents-upload/documents-upload.utility";
import { GenerateSrcUrl } from "../jobs-documents-gallery/job-documents.utility";
import LoadingSpinner from "../loading-spinner/loading-spinner.component";
import { useNotification } from "../../contexts/Notifications/notificationContext.jsx";

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  bodyshop: selectBodyshop
});
const mapDispatchToProps = (dispatch) => ({
  //setUserLanguage: language => dispatch(setUserLanguage(language))
});

export function DocumentEditorComponent({ currentUser, bodyshop, document }) {
  const imgRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [uploaded, setuploaded] = useState(false);
  const markerArea = useRef(null);
  const { t } = useTranslation();
  const notification = useNotification();

  const triggerUpload = useCallback(
    async (dataUrl) => {
      setLoading(true);
      handleUpload(
        {
          filename: `${document.key.split("/").pop()}-${Date.now()}.jpg`,
          file: await b64toBlob(dataUrl),
          onSuccess: () => {
            setLoading(false);
            setuploaded(true);
          },
          onError: () => setLoading(false)
        },
        {
          bodyshop: bodyshop,
          uploaded_by: currentUser.email,
          jobId: document.jobid,
          //billId: billId,
          tagsArray: ["edited"]
          //callback: callbackAfterUpload,
        },
        notification
      );
    },
    [bodyshop, currentUser, document, notification]
  );

  useEffect(() => {
    if (imgRef.current !== null) {
      // create a marker.js MarkerArea
      markerArea.current = new markerjs2.MarkerArea(imgRef.current);

      // attach an event handler to assign annotated image back to our image element
      markerArea.current.addEventListener("close", (closeEvent) => {});

      markerArea.current.addEventListener("render", (event) => {
        const dataUrl = event.dataUrl;
        imgRef.current.src = dataUrl;
        markerArea.current.close();
        triggerUpload(dataUrl);
      });
      // launch marker.js

      markerArea.current.renderAtNaturalSize = true;
      markerArea.current.renderImageType = "image/jpeg";
      markerArea.current.renderImageQuality = 1;
      //markerArea.current.settings.displayMode = "inline";
      markerArea.current.show();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerUpload]);

  async function b64toBlob(url) {
    const res = await fetch(url);
    return await res.blob();
  }

  return (
    <div>
      {!loading && !uploaded && (
        <img
          ref={imgRef}
          src={GenerateSrcUrl(document)}
          alt="sample"
          crossOrigin="anonymous"
          style={{ maxWidth: "90vw", maxHeight: "90vh" }}
        />
      )}
      {loading && <LoadingSpinner message={t("documents.labels.uploading")} />}
      {uploaded && <Result status="success" title={t("documents.successes.edituploaded")} />}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentEditorComponent);

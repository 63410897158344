import ModalsActionTypes from "./modals.types";

export const toggleModalVisible = (modalName) => ({
  type: ModalsActionTypes.TOGGLE_MODAL_VISIBLE,
  payload: modalName
});

//Modal Context: {context (context object), modal(name of modal)}
export const setModalContext = (modalContext) => ({
  type: ModalsActionTypes.SET_MODAL_CONTEXT,
  payload: modalContext
});

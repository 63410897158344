import MessagingActionTypes from "./messaging.types";

export const toggleChatVisible = () => ({
  type: MessagingActionTypes.TOGGLE_CHAT_VISIBLE
  //payload: user
});

export const sendMessage = (message) => ({
  type: MessagingActionTypes.SEND_MESSAGE,
  payload: message
});

export const sendMessageSuccess = (message) => ({
  type: MessagingActionTypes.SEND_MESSAGE_SUCCESS,
  payload: message
});

export const sendMessageFailure = (error) => ({
  type: MessagingActionTypes.SEND_MESSAGE_FAILURE,
  payload: error
});
export const setSelectedConversation = (conversationId) => ({
  type: MessagingActionTypes.SET_SELECTED_CONVERSATION,
  payload: conversationId
});

export const openChatByPhone = (phoneNumber) => ({
  type: MessagingActionTypes.OPEN_CHAT_BY_PHONE,
  payload: phoneNumber
});

export const setMessage = (message) => ({
  type: MessagingActionTypes.SET_MESSAGE,
  payload: message
});

import MessagingActionTypes from "./messaging.types";

const INITIAL_STATE = {
  open: false,
  selectedConversationId: null,
  isSending: false,
  error: null,
  message: null,
  searchingForConversation: false
};

const messagingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MessagingActionTypes.SET_MESSAGE:
      return { ...state, message: action.payload };
    case MessagingActionTypes.TOGGLE_CHAT_VISIBLE:
      return {
        ...state,
        open: !state.open
      };
    case MessagingActionTypes.OPEN_CHAT_BY_PHONE:
      return {
        ...state,
        searchingForConversation: true
      };
    case MessagingActionTypes.SET_SELECTED_CONVERSATION:
      return {
        ...state,
        open: true,
        searchingForConversation: false,
        selectedConversationId: action.payload
      };
    case MessagingActionTypes.SEND_MESSAGE:
      return {
        ...state,
        error: null,
        isSending: true
      };
    case MessagingActionTypes.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        message: "",
        isSending: false
      };
    case MessagingActionTypes.SEND_MESSAGE_FAILURE:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export default messagingReducer;

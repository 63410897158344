import axios from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { logImEXEvent } from "../../firebase/firebase.utils";
import { selectBodyshop } from "../user/user.selectors";
import { techLoginFailure, techLoginSuccess } from "./tech.actions";
import TechActionTypes from "./tech.types";

export function* onSignInStart() {
  yield takeLatest(TechActionTypes.TECH_LOGIN_START, signInStart);
}

export function* signInStart({ payload: { employeeid, pin } }) {
  try {
    logImEXEvent("redux_tech_sign_in");

    const bodyshop = yield select(selectBodyshop);
    const response = yield call(axios.post, "/tech/login", {
      shopid: bodyshop.id,
      employeeid: employeeid,
      pin: pin
    });

    const { valid, technician, error } = response.data;

    if (valid) {
      yield put(techLoginSuccess(technician));
    } else {
      yield put(techLoginFailure(error));
    }
  } catch (error) {
    yield put(techLoginFailure(error));
  }
}

export function* techSagas() {
  yield all([call(onSignInStart)]);
}

import { Tooltip } from "antd";
import dayjs from "../utils/day";
import React from "react";

export function DateFormatter(props) {
  return props.children ? dayjs(props.children).format(props.includeDay ? "ddd MM/DD/YYYY" : "MM/DD/YYYY") : null;
}

export function DateTimeFormatter(props) {
  return props.children ? dayjs(props.children).format(props.format ? props.format : "MM/DD/YYYY hh:mm a") : null;
}

export function DateTimeFormatterFunction(date) {
  return dayjs(date).format("MM/DD/YYYY hh:mm a");
}

export function TimeFormatter(props) {
  return props.children ? dayjs(props.children).format(props.format ? props.format : "hh:mm a") : null;
}

export function TimeAgoFormatter(props) {
  const m = dayjs(props.children);
  return props.children ? (
    <Tooltip placement="top" title={m.format("MM/DD/YYY hh:mm A")}>
      {m.fromNow()}
    </Tooltip>
  ) : null;
}

export function DateTimeFormat(value) {
  return dayjs(value).format("MM/DD/YYYY hh:mm A");
}

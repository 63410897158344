import { useQuery } from "@apollo/client";
import { Result } from "antd";
import queryString from "query-string";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { QUERY_BODYSHOP } from "../../graphql/bodyshop.queries";
import { GET_DOCUMENT_BY_PK } from "../../graphql/documents.queries";
import { setBodyshop } from "../../redux/user/user.actions";
import AlertComponent from "../alert/alert.component";
import LoadingSpinner from "../loading-spinner/loading-spinner.component";
import DocumentEditor from "./document-editor.component";

const mapDispatchToProps = (dispatch) => ({
  setBodyshop: (bs) => dispatch(setBodyshop(bs))
});

export default connect(null, mapDispatchToProps)(DocumentEditorContainer);

export function DocumentEditorContainer({ setBodyshop }) {
  //Get the image details for the image to be saved.
  //Get the document id from the search string.
  const { documentId } = queryString.parse(useLocation().search);
  const { t } = useTranslation();
  const {
    loading: loadingShop,
    error: errorShop,
    data: dataShop
  } = useQuery(QUERY_BODYSHOP, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  useEffect(() => {
    if (dataShop) setBodyshop(dataShop.bodyshops[0]);
  }, [dataShop, setBodyshop]);

  const { loading, error, data } = useQuery(GET_DOCUMENT_BY_PK, {
    variables: { documentId },
    skip: !documentId,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only"
  });

  if (loading || loadingShop) return <LoadingSpinner />;
  if (error || errorShop) return <AlertComponent message={error.message || errorShop.message} type="error" />;

  if (!data || !data.documents_by_pk) return <Result status="404" title={t("general.errors.notfound")} />;
  return (
    <div>
      <DocumentEditor document={data ? data.documents_by_pk : null} />
    </div>
  );
}

import i18n from "i18next";
//import { store } from "../redux/store";
import InstanceRenderManager from "./instanceRenderMgr";

export const EmailSettings = {
  fromNameDefault: InstanceRenderManager({
    imex: "ImEX Online",
    rome: "Rome Online"
  }),
  fromAddress: InstanceRenderManager({
    imex: "noreply@imex.online",
    rome: "noreply@romeonline.io"
  })
};

export const TemplateList = (type, context) => {
  //const { bodyshop } = store.getState().user;
  return {
    //If there's no type or the type is job, send it back.
    ...(!type || type === "job"
      ? {
          casl_authorization: {
            title: i18n.t("printcenter.jobs.casl_authorization"),
            description: "",
            subject: i18n.t("printcenter.jobs.casl_authorization"),
            key: "casl_authorization",
            disabled: false,
            group: "authorization",
            regions: {
              CA: true
            }
          },
          fippa_authorization: {
            title: i18n.t("printcenter.jobs.fippa_authorization"),
            description: "",
            subject: i18n.t("printcenter.jobs.fippa_authorization"),
            key: "fippa_authorization",
            disabled: false,
            group: "authorization"
          },
          diagnostic_authorization: {
            title: i18n.t("printcenter.jobs.diagnostic_authorization"),
            description: "",
            subject: i18n.t("printcenter.jobs.diagnostic_authorization"),
            key: "diagnostic_authorization",
            disabled: false,
            group: "authorization"
          },
          mechanical_authorization: {
            title: i18n.t("printcenter.jobs.mechanical_authorization"),
            description: "",
            subject: i18n.t("printcenter.jobs.mechanical_authorization"),
            key: "mechanical_authorization",
            disabled: false,
            group: "authorization"
          },
          appointment_reminder: {
            title: i18n.t("printcenter.jobs.appointment_reminder"),
            description: "",
            subject: i18n.t("printcenter.jobs.appointment_reminder"),
            key: "appointment_reminder",
            disabled: false,
            group: "pre"
          },
          estimate_followup: {
            title: i18n.t("printcenter.jobs.estimate_followup"),
            description: "",
            subject: i18n.t("printcenter.jobs.estimate_followup"),
            key: "estimate_followup",
            disabled: false,
            group: "pre"
          },
          express_repair_checklist: {
            title: i18n.t("printcenter.jobs.express_repair_checklist"),
            description: "",
            subject: i18n.t("printcenter.jobs.express_repair_checklist"),
            key: "express_repair_checklist",
            disabled: false,
            group: "pre"
          },
          glass_express_checklist: {
            title: i18n.t("printcenter.jobs.glass_express_checklist"),
            description: "",
            subject: i18n.t("printcenter.jobs.glass_express_checklist"),
            key: "glass_express_checklist",
            disabled: false,
            group: "pre"
          },
          stolen_recovery_checklist: {
            title: i18n.t("printcenter.jobs.stolen_recovery_checklist"),
            description: "",
            subject: i18n.t("printcenter.jobs.stolen_recovery_checklist"),
            key: "stolen_recovery_checklist",
            disabled: false,
            group: "pre"
          },
          vehicle_check_in: {
            title: i18n.t("printcenter.jobs.vehicle_check_in"),
            description: "",
            subject: i18n.t("printcenter.jobs.vehicle_check_in"),
            key: "vehicle_check_in",
            disabled: false,
            group: "pre"
          },
          parts_order_history: {
            title: i18n.t("printcenter.jobs.parts_order_history"),
            description: "",
            subject: i18n.t("printcenter.jobs.parts_order_history"),
            key: "parts_order_history",
            disabled: false,
            group: "ro"
          },
          job_notes: {
            title: i18n.t("printcenter.jobs.job_notes"),
            description: "",
            subject: i18n.t("printcenter.jobs.job_notes"),
            key: "job_notes",
            disabled: false,
            group: "ro"
          },
          ro_with_description: {
            title: i18n.t("printcenter.jobs.ro_with_description"),
            description: "",
            subject: i18n.t("printcenter.jobs.ro_with_description"),
            key: "ro_with_description",
            disabled: false,
            group: "ro"
          },
          window_tag: {
            title: i18n.t("printcenter.jobs.window_tag"),
            description: "",
            subject: i18n.t("printcenter.jobs.window_tag"),
            key: "window_tag",
            disabled: false,
            group: "ro"
          },
          supplement_request: {
            title: i18n.t("printcenter.jobs.supplement_request"),
            description: "",
            subject: i18n.t("printcenter.jobs.supplement_request"),
            key: "supplement_request",
            disabled: false,
            group: "ro"
          },
          estimate: {
            title: i18n.t("printcenter.jobs.estimate"),
            description: "",
            subject: i18n.t("printcenter.jobs.estimate"),
            key: "estimate",
            disabled: false,
            group: "ro"
          },
          parts_list: {
            title: i18n.t("printcenter.jobs.parts_list"),
            description: "",
            subject: i18n.t("printcenter.jobs.parts_list"),
            key: "parts_list",
            disabled: false,
            group: "ro"
          },
          coversheet_portrait: {
            title: i18n.t("printcenter.jobs.coversheet_portrait"),
            description: "",
            subject: i18n.t("printcenter.jobs.coversheet_portrait"),
            key: "coversheet_portrait",
            disabled: false,
            group: "ro"
          },
          coversheet_landscape: {
            title: i18n.t("printcenter.jobs.coversheet_landscape"),
            description: "",
            subject: i18n.t("printcenter.jobs.coversheet_landscape"),
            key: "coversheet_landscape",
            disabled: false,
            group: "ro"
          },
          key_tag: {
            title: i18n.t("printcenter.jobs.key_tag"),
            description: "",
            subject: i18n.t("printcenter.jobs.key_tag"),
            key: "key_tag",
            disabled: false,
            group: "ro"
          },
          paint_grid: {
            title: i18n.t("printcenter.jobs.paint_grid"),
            description: "",
            subject: i18n.t("printcenter.jobs.paint_grid"),
            key: "paint_grid",
            disabled: false,
            group: "ro"
          },
          worksheet_by_line_number: {
            title: i18n.t("printcenter.jobs.worksheet_by_line_number"),
            description: "",
            subject: i18n.t("printcenter.jobs.worksheet_by_line_number"),
            key: "worksheet_by_line_number",
            disabled: false,
            group: "worksheet",
            enhanced_payroll: false
          },
          worksheet_by_line_number_enhanced: {
            title: i18n.t("printcenter.jobs.worksheet_by_line_number"),
            description: "",
            subject: i18n.t("printcenter.jobs.worksheet_by_line_number"),
            key: "worksheet_by_line_number_enhanced",
            disabled: false,
            group: "worksheet",
            enhanced_payroll: true
          },
          worksheet_sorted_by_operation_type: {
            title: i18n.t("printcenter.jobs.worksheet_sorted_by_operation_type"),
            description: "",
            subject: i18n.t("printcenter.jobs.worksheet_sorted_by_operation_type"),
            key: "worksheet_sorted_by_operation_type",
            disabled: false,
            group: "worksheet",
            enhanced_payroll: false
          },
          worksheet_sorted_by_operation_type_enhanced: {
            title: i18n.t("printcenter.jobs.worksheet_sorted_by_operation_type"),
            description: "",
            subject: i18n.t("printcenter.jobs.worksheet_sorted_by_operation_type"),
            key: "worksheet_sorted_by_operation_type_enhanced",
            disabled: false,
            group: "worksheet",
            enhanced_payroll: true
          },
          worksheet_sorted_by_operation: {
            title: i18n.t("printcenter.jobs.worksheet_sorted_by_operation"),
            description: "",
            subject: i18n.t("printcenter.jobs.worksheet_sorted_by_operation"),
            key: "worksheet_sorted_by_operation",
            disabled: false,
            group: "worksheet",
            enhanced_payroll: false
          },
          worksheet_sorted_by_operation_enhanced: {
            title: i18n.t("printcenter.jobs.worksheet_sorted_by_operation"),
            description: "",
            subject: i18n.t("printcenter.jobs.worksheet_sorted_by_operation"),
            key: "worksheet_sorted_by_operation_enhanced",
            disabled: false,
            group: "worksheet",
            enhanced_payroll: true
          },
          worksheet_sorted_by_operation_no_hours: {
            title: i18n.t("printcenter.jobs.worksheet_sorted_by_operation_no_hours"),
            description: "",
            subject: i18n.t("printcenter.jobs.worksheet_sorted_by_operation_no_hours"),
            key: "worksheet_sorted_by_operation_no_hours",
            disabled: false,
            group: "worksheet",
            enhanced_payroll: false
          },
          worksheet_sorted_by_operation_no_hours_enhanced: {
            title: i18n.t("printcenter.jobs.worksheet_sorted_by_operation_no_hours"),
            description: "",
            subject: i18n.t("printcenter.jobs.worksheet_sorted_by_operation_no_hours"),
            key: "worksheet_sorted_by_operation_no_hours_enhanced",
            disabled: false,
            group: "worksheet",
            enhanced_payroll: true
          },
          worksheet_sorted_by_operation_part_type: {
            title: i18n.t("printcenter.jobs.worksheet_sorted_by_operation_part_type"),
            description: "",
            subject: i18n.t("printcenter.jobs.worksheet_sorted_by_operation_part_type"),
            key: "worksheet_sorted_by_operation_part_type",
            disabled: false,
            group: "worksheet",
            enhanced_payroll: false
          },
          worksheet_sorted_by_operation_part_type_enhanced: {
            title: i18n.t("printcenter.jobs.worksheet_sorted_by_operation_part_type"),
            description: "",
            subject: i18n.t("printcenter.jobs.worksheet_sorted_by_operation_part_type"),
            key: "worksheet_sorted_by_operation_part_type_enhanced",
            disabled: false,
            group: "worksheet",
            enhanced_payroll: true
          },
          payments_by_job: {
            title: i18n.t("printcenter.jobs.payments_by_job"),
            description: "",
            subject: i18n.t("printcenter.jobs.payments_by_job"),
            key: "payments_by_job",
            disabled: false,
            group: "financial"
          },
          final_invoice: {
            title: i18n.t("printcenter.jobs.final_invoice"),
            description: "",
            subject: i18n.t("printcenter.jobs.final_invoice"),
            key: "final_invoice",
            disabled: false,
            group: "financial"
          },
          payment_request: {
            title: i18n.t("printcenter.jobs.payment_request"),
            description: "",
            subject: i18n.t("printcenter.jobs.payment_request"),
            key: "payment_request",
            disabled: false,
            group: "financial"
          },
          invoice_total_payable: {
            title: i18n.t("printcenter.jobs.invoice_total_payable"),
            description: "",
            subject: i18n.t("printcenter.jobs.invoice_total_payable"),
            key: "invoice_total_payable",
            disabled: false,
            group: "financial"
          },
          invoice_customer_payable: {
            title: i18n.t("printcenter.jobs.invoice_customer_payable"),
            description: "",
            subject: i18n.t("printcenter.jobs.invoice_customer_payable"),
            key: "invoice_customer_payable",
            disabled: false,
            group: "financial"
          },
          ro_totals: {
            title: i18n.t("printcenter.jobs.ro_totals"),
            description: "",
            subject: i18n.t("printcenter.jobs.ro_totals"),
            key: "ro_totals",
            disabled: false,
            group: "financial"
          },
          job_costing_ro: {
            title: i18n.t("printcenter.jobs.job_costing_ro"),
            description: "",
            subject: i18n.t("printcenter.jobs.job_costing_ro"),
            key: "job_costing_ro",
            disabled: false,
            group: "financial"
          },
          purchases_by_ro_detail: {
            title: i18n.t("printcenter.jobs.purchases_by_ro_detail"),
            description: "",
            subject: i18n.t("printcenter.jobs.purchases_by_ro_detail"),
            key: "purchases_by_ro_detail",
            disabled: false,
            group: "financial",
            featureNameRestricted: "bills"
          },
          purchases_by_ro_summary: {
            title: i18n.t("printcenter.jobs.purchases_by_ro_summary"),
            description: "",
            subject: i18n.t("printcenter.jobs.purchases_by_ro_summary"),
            key: "purchases_by_ro_summary",
            disabled: false,
            group: "financial",
            featureNameRestricted: "bills"
          },
          filing_coversheet_portrait: {
            title: i18n.t("printcenter.jobs.filing_coversheet_portrait"),
            description: "",
            subject: i18n.t("printcenter.jobs.filing_coversheet_portrait"),
            key: "filing_coversheet_portrait",
            disabled: false,
            group: "post"
          },
          filing_coversheet_landscape: {
            title: i18n.t("printcenter.jobs.filing_coversheet_landscape"),
            description: "",
            subject: i18n.t("printcenter.jobs.filing_coversheet_landscape"),
            key: "filing_coversheet_landscape",
            disabled: false,
            group: "post"
          },
          qc_sheet: {
            title: i18n.t("printcenter.jobs.qc_sheet"),
            description: "",
            subject: i18n.t("printcenter.jobs.qc_sheet"),
            key: "qc_sheet",
            disabled: false,
            group: "post"
          },
          vehicle_delivery_check: {
            title: i18n.t("printcenter.jobs.vehicle_delivery_check"),
            description: "",
            subject: i18n.t("printcenter.jobs.vehicle_delivery_check"),
            key: "vehicle_delivery_check",
            disabled: false,
            group: "post"
          },
          guarantee: {
            title: i18n.t("printcenter.jobs.guarantee"),
            description: "",
            subject: i18n.t("printcenter.jobs.guarantee"),
            key: "guarantee",
            disabled: false,
            group: "post"
          },
          csi_invitation: {
            title: i18n.t("printcenter.jobs.csi_invitation"),
            description: "",
            key: "csi_invitation",
            subject: i18n.t("printcenter.jobs.csi_invitation"),
            disabled: false,
            group: "post",
            featureNameRestricted: "csi"
          },
          window_tag_sublet: {
            title: i18n.t("printcenter.jobs.window_tag_sublet"),
            description: "",
            key: "window_tag_sublet",
            subject: i18n.t("printcenter.jobs.window_tag_sublet"),
            disabled: false,
            group: "ro"
          },
          thank_you_ro: {
            title: i18n.t("printcenter.jobs.thank_you_ro"),
            description: "",
            key: "thank_you_ro",
            subject: i18n.t("printcenter.jobs.thank_you_ro"),
            disabled: false,
            group: "post"
          },
          parts_label_single: {
            title: i18n.t("printcenter.jobs.parts_label_single"),
            description: "",
            key: "parts_label_single",
            subject: i18n.t("printcenter.jobs.parts_label_single"),
            disabled: false,
            group: "ro",
            ignoreCustomMargins: true
          },
          envelope_return_address: {
            title: i18n.t("printcenter.jobs.envelope_return_address"),
            description: "",
            subject: i18n.t("printcenter.jobs.envelope_return_address"),
            key: "envelope_return_address",
            disabled: false,
            group: "ro",
            ignoreCustomMargins: true
          },
          sgi_certificate_of_repairs: {
            title: i18n.t("printcenter.jobs.sgi_certificate_of_repairs"),
            description: "",
            key: "sgi_certificate_of_repairs",
            subject: i18n.t("printcenter.jobs.sgi_certificate_of_repairs"),
            disabled: false,
            group: "ro",
            regions: {
              CA_SK: true
            }
          },
          sgi_windshield_auth: {
            title: i18n.t("printcenter.jobs.sgi_windshield_auth"),
            description: "",
            key: "sgi_windshield_auth",
            subject: i18n.t("printcenter.jobs.sgi_windshield_auth"),
            disabled: false,
            group: "pre",
            regions: {
              CA_SK: true
            }
          },
          mpi_final_acct_sheet: {
            title: i18n.t("printcenter.jobs.mpi_final_acct_sheet"),
            description: "",
            key: "mpi_final_acct_sheet",
            subject: i18n.t("printcenter.jobs.mpi_final_acct_sheet"),
            disabled: false,
            group: "post",
            regions: {
              CA_MB: true
            }
          },
          mpi_final_repair_acct_sheet: {
            title: i18n.t("printcenter.jobs.mpi_final_repair_acct_sheet"),
            description: "",
            key: "mpi_final_repair_acct_sheet",
            subject: i18n.t("printcenter.jobs.mpi_final_repair_acct_sheet"),
            disabled: false,
            group: "post",
            regions: {
              CA_MB: true
            }
          },
          mpi_eglass_auth: {
            title: i18n.t("printcenter.jobs.mpi_eglass_auth"),
            description: "",
            key: "mpi_eglass_auth",
            subject: i18n.t("printcenter.jobs.mpi_eglass_auth"),
            disabled: false,
            group: "pre",
            regions: {
              CA_MB: true
            }
          },
          mpi_animal_checklist: {
            title: i18n.t("printcenter.jobs.mpi_animal_checklist"),
            description: "",
            key: "mpi_animal_checklist",
            subject: i18n.t("printcenter.jobs.mpi_animal_checklist"),
            disabled: false,
            group: "pre",
            regions: {
              CA_MB: true
            }
          },
          ab_proof_of_loss: {
            title: i18n.t("printcenter.jobs.ab_proof_of_loss"),
            description: "",
            key: "ab_proof_of_loss",
            subject: i18n.t("printcenter.jobs.ab_proof_of_loss"),
            disabled: false,
            group: "pre",
            regions: {
              CA_AB: true
            }
          },
          // parts_label_multi: {
          //   title: i18n.t("printcenter.jobs.parts_label_multi"),
          //   description: "",
          //   key: "parts_label_multi",
          //   subject: i18n.t("printcenter.jobs.parts_label_multi"),
          //   disabled: false,
          //   group: "ro",
          // },
          iou_form: {
            title: i18n.t("printcenter.jobs.iou_form"),
            description: "",
            subject: i18n.t("printcenter.jobs.iou_form"),
            key: "iou_form",
            disabled: false,
            group: "post"
          },
          lag_time_ro: {
            title: i18n.t("printcenter.jobs.lag_time_ro"),
            description: "",
            subject: i18n.t("printcenter.jobs.lag_time_ro"),
            key: "lag_time_ro",
            disabled: false,
            group: "ro"
          },
          rental_reservation: {
            title: i18n.t("printcenter.jobs.rental_reservation"),
            description: "",
            subject: i18n.t("printcenter.jobs.rental_reservation"),
            key: "rental_reservation",
            disabled: false,
            group: "pre"
          },
          timetickets_ro: {
            title: i18n.t("printcenter.jobs.timetickets_ro"),
            description: "",
            subject: i18n.t("printcenter.jobs.timetickets_ro"),
            key: "timetickets_ro",
            disabled: false,
            group: "financial",
            featureNameRestricted: "timetickets"
          },
          dms_posting_sheet: {
            title: i18n.t("printcenter.jobs.dms_posting_sheet"),
            description: "",
            subject: i18n.t("printcenter.jobs.dms_posting_sheet"),
            key: "dms_posting_sheet",
            disabled: false,
            group: "financial",
            dms: true
          },
          worksheet_sorted_by_team: {
            title: i18n.t("printcenter.jobs.worksheet_sorted_by_team"),
            description: "",
            subject: i18n.t("printcenter.jobs.worksheet_sorted_by_team"),
            key: "worksheet_sorted_by_team",
            disabled: false,
            group: "worksheet",
            enhanced_payroll: true
          },
          committed_timetickets_ro: {
            title: i18n.t("printcenter.jobs.committed_timetickets_ro"),
            description: "",
            subject: i18n.t("printcenter.jobs.committed_timetickets_ro"),
            key: "committed_timetickets_ro",
            disabled: false,
            group: "financial",
            enhanced_payroll: true,
            featureNameRestricted: "timetickets"
          },
          job_lifecycle_ro: {
            title: i18n.t("printcenter.jobs.job_lifecycle_ro"),
            description: "",
            subject: i18n.t("printcenter.jobs.job_lifecycle_ro"),
            key: "job_lifecycle_ro",
            disabled: false,
            group: "post"
            //featureNameRestricted: "lifecycle"
          },
          job_tasks: {
            title: i18n.t("printcenter.jobs.job_tasks"),
            description: "",
            subject: i18n.t("printcenter.jobs.job_tasks"),
            key: "job_tasks",
            disabled: false,
            group: "ro"
          }
        }
      : {}),
    ...(!type || type === "job_special"
      ? {
          special_thirdpartypayer: {
            title: i18n.t("printcenter.jobs.thirdpartypayer"),
            description: "",
            key: "special_thirdpartypayer",
            disabled: false
          },
          folder_label_multiple: {
            title: i18n.t("printcenter.jobs.folder_label_multiple"),
            description: "",
            key: "folder_label_multiple",
            disabled: false
          },
          parts_label_multiple: {
            title: i18n.t("printcenter.jobs.parts_label_multiple"),
            description: "",
            key: "parts_label_multiple",
            disabled: false
          },
          parts_invoice_label_single: {
            title: i18n.t("printcenter.jobs.parts_invoice_label_single"),
            description: "",
            key: "parts_invoice_label_single",
            disabled: false,
            ignoreCustomMargins: true
          },
          csi_invitation_action: {
            title: i18n.t("printcenter.jobs.csi_invitation_action"),
            description: "",
            key: "csi_invitation_action",
            subject: i18n.t("printcenter.jobs.csi_invitation_action"),
            disabled: false,
            featureNameRestricted: "csi"
          },
          individual_job_note: {
            title: i18n.t("printcenter.jobs.individual_job_note"),
            description: "",
            key: "individual_job_note",
            subject: i18n.t("printcenter.subjects.jobs.individual_job_note", {
              ro_number: (context && context.ro_number) || ""
            }),
            disabled: false
          },
          parts_dispatch: {
            title: i18n.t("printcenter.jobs.parts_dispatch"),
            description: "",
            key: "parts_dispatch",
            subject: i18n.t("printcenter.subjects.jobs.parts_dispatch", {
              ro_number: (context && context.ro_number) || ""
            }),
            disabled: false
          }
        }
      : {}),
    ...(!type || type === "appointment"
      ? {
          appointment_confirmation: {
            title: i18n.t("printcenter.appointments.appointment_confirmation"),
            description: "",
            subject: i18n.t("printcenter.appointments.appointment_confirmation"),
            key: "appointment_confirmation",
            disabled: false
          }
        }
      : {}),
    ...(!type || type === "partsorder"
      ? {
          parts_order: {
            title: i18n.t("printcenter.jobs.parts_order"),
            description: "",
            key: "parts_order",
            subject: i18n.t("printcenter.subjects.jobs.parts_order", {
              ro_number: context && context.job && context.job.ro_number,
              name: (
                (context && context.job && context.job.ownr_ln) ||
                (context && context.job && context.job.ownr_co_nm) ||
                ""
              ).trim()
            }),
            disabled: false
          },
          sublet_order: {
            title: i18n.t("printcenter.jobs.sublet_order"),
            description: "",
            key: "sublet_order",
            subject: i18n.t("printcenter.subjects.jobs.sublet_order", {
              ro_number: context && context.job && context.job.ro_number,
              name: (
                (context && context.job && context.job.ownr_ln) ||
                (context && context.job && context.job.ownr_co_nm) ||
                ""
              ).trim()
            }),
            disabled: false
          },
          parts_return_slip: {
            title: i18n.t("printcenter.jobs.parts_return_slip"),
            subject: i18n.t("printcenter.subjects.jobs.parts_return_slip", {
              ro_number: context && context.job && context.job.ro_number,
              name: (
                (context && context.job && context.job.ownr_ln) ||
                (context && context.job && context.job.ownr_co_nm) ||
                ""
              ).trim()
            }),
            description: "",
            key: "parts_return_slip",
            disabled: false
          }
        }
      : {}),
    ...(!type || type === "payment"
      ? {
          payment_receipt: {
            title: i18n.t("printcenter.jobs.payment_receipt"),
            description: "",
            subject: i18n.t("printcenter.jobs.payment_receipt"),
            key: "payment_receipt",
            disabled: false
          }
        }
      : {}),
    ...(!type || type === "csi" ? {} : {}),
    ...(!type || type === "report_center"
      ? {
          hours_sold_detail_closed: {
            title: i18n.t("reportcenter.templates.hours_sold_detail_closed"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_detail_closed"),
            key: "hours_sold_detail_closed",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          hours_sold_detail_closed_ins_co: {
            title: i18n.t("reportcenter.templates.hours_sold_detail_closed_ins_co"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_detail_closed_ins_co"),
            key: "hours_sold_detail_closed_ins_co",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          hours_sold_summary_closed: {
            title: i18n.t("reportcenter.templates.hours_sold_summary_closed"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_summary_closed"),
            key: "hours_sold_summary_closed",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          hours_sold_summary_closed_ins_co: {
            title: i18n.t("reportcenter.templates.hours_sold_summary_closed_ins_co"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_summary_closed_ins_co"),
            key: "hours_sold_summary_closed_ins_co",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          hours_sold_detail_open: {
            title: i18n.t("reportcenter.templates.hours_sold_detail_open"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_detail_open"),
            key: "hours_sold_detail_open",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "sales"
          },
          hours_sold_detail_open_ins_co: {
            title: i18n.t("reportcenter.templates.hours_sold_detail_open_ins_co"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_detail_open_ins_co"),
            key: "hours_sold_detail_open_ins_co",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "sales"
          },
          hours_sold_summary_open: {
            title: i18n.t("reportcenter.templates.hours_sold_summary_open"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_summary_open"),
            key: "hours_sold_summary_open",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "sales"
          },
          hours_sold_summary_open_ins_co: {
            title: i18n.t("reportcenter.templates.hours_sold_summary_open_ins_co"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_summary_open_ins_co"),
            key: "hours_sold_summary_open_ins_co",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "sales"
          },
          hours_sold_detail_closed_csr: {
            title: i18n.t("reportcenter.templates.hours_sold_detail_closed_csr"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_detail_closed_csr"),
            key: "hours_sold_detail_closed_csr",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          hours_sold_detail_open_csr: {
            title: i18n.t("reportcenter.templates.hours_sold_detail_open_csr"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_detail_open_csr"),
            key: "hours_sold_detail_open_csr",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "sales"
          },
          hours_sold_summary_closed_csr: {
            title: i18n.t("reportcenter.templates.hours_sold_summary_closed_csr"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_summary_closed_csr"),
            key: "hours_sold_summary_closed_csr",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          hours_sold_summary_open_csr: {
            title: i18n.t("reportcenter.templates.hours_sold_summary_open_csr"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_summary_open_csr"),
            key: "hours_sold_summary_open_csr",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          hours_sold_detail_closed_estimator: {
            title: i18n.t("reportcenter.templates.hours_sold_detail_closed_estimator"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_detail_closed_estimator"),
            key: "hours_sold_detail_closed_estimator",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          hours_sold_detail_open_estimator: {
            title: i18n.t("reportcenter.templates.hours_sold_detail_open_estimator"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_detail_open_estimator"),
            key: "hours_sold_detail_open_estimator",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "sales"
          },
          hours_sold_summary_closed_estimator: {
            title: i18n.t("reportcenter.templates.hours_sold_summary_closed_estimator"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_summary_closed_estimator"),
            key: "hours_sold_summary_closed_estimator",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          hours_sold_summary_open_estimator: {
            title: i18n.t("reportcenter.templates.hours_sold_summary_open_estimator"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_summary_open_estimator"),
            key: "hours_sold_summary_open_estimator",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          hours_sold_summary_open_status: {
            title: i18n.t("reportcenter.templates.hours_sold_summary_open_status"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_summary_open_status"),
            key: "hours_sold_summary_open_status",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          hours_sold_summary_closed_status: {
            title: i18n.t("reportcenter.templates.hours_sold_summary_closed_status"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_summary_closed_status"),
            key: "hours_sold_summary_closed_status",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          hours_sold_detail_open_status: {
            title: i18n.t("reportcenter.templates.hours_sold_detail_open_status"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_detail_open_status"),
            key: "hours_sold_detail_open_status",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          hours_sold_detail_closed_status: {
            title: i18n.t("reportcenter.templates.hours_sold_detail_closed_status"),
            description: "",
            subject: i18n.t("reportcenter.templates.hours_sold_detail_closed_status"),
            key: "hours_sold_detail_closed_status",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          purchases_by_date_range_detail: {
            title: i18n.t("reportcenter.templates.purchases_by_date_range_detail"),
            subject: i18n.t("reportcenter.templates.purchases_by_date_range_detail"),
            key: "purchases_by_date_range_detail",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.bills"),
              field: i18n.t("bills.fields.date")
            },
            group: "purchases"
          },
          purchases_by_date_range_summary: {
            title: i18n.t("reportcenter.templates.purchases_by_date_range_summary"),
            subject: i18n.t("reportcenter.templates.purchases_by_date_range_summary"),
            key: "purchases_by_date_range_summary",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.bills"),
              field: i18n.t("bills.fields.date")
            },
            group: "purchases"
          },
          purchases_by_vendor_detailed_date_range: {
            title: i18n.t("reportcenter.templates.purchases_by_vendor_detailed_date_range"),
            subject: i18n.t("reportcenter.templates.purchases_by_vendor_detailed_date_range"),
            key: "purchases_by_vendor_detailed_date_range",
            idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.bills"),
              field: i18n.t("bills.fields.date")
            },
            group: "purchases"
          },
          purchases_by_vendor_summary_date_range: {
            title: i18n.t("reportcenter.templates.purchases_by_vendor_summary_date_range"),
            subject: i18n.t("reportcenter.templates.purchases_by_vendor_summary_date_range"),
            key: "purchases_by_vendor_summary_date_range",
            idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.bills"),
              field: i18n.t("bills.fields.date")
            },
            group: "purchases"
          },
          purchases_by_cost_center_detail: {
            title: i18n.t("reportcenter.templates.purchases_by_cost_center_detail"),
            description: "",
            subject: i18n.t("reportcenter.templates.purchases_by_cost_center_detail"),
            key: "purchases_by_cost_center_detail",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.bills"),
              field: i18n.t("bills.fields.date")
            },
            group: "purchases"
          },
          purchases_by_cost_center_summary: {
            title: i18n.t("reportcenter.templates.purchases_by_cost_center_summary"),
            description: "",
            subject: i18n.t("reportcenter.templates.purchases_by_cost_center_summary"),
            key: "purchases_by_cost_center_summary",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.bills"),
              field: i18n.t("bills.fields.date")
            },
            group: "purchases"
          },
          purchases_grouped_by_vendor_detailed: {
            title: i18n.t("reportcenter.templates.purchases_grouped_by_vendor_detailed"),
            description: "",
            subject: i18n.t("reportcenter.templates.purchases_grouped_by_vendor_detailed"),
            key: "purchases_grouped_by_vendor_detailed",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("bills.fields.date")
            },
            group: "purchases"
          },
          purchases_grouped_by_vendor_summary: {
            title: i18n.t("reportcenter.templates.purchases_grouped_by_vendor_summary"),
            description: "",
            subject: i18n.t("reportcenter.templates.purchases_grouped_by_vendor_summary"),
            key: "purchases_grouped_by_vendor_summary",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("bills.fields.date")
            },
            group: "purchases"
          },
          purchases_by_ro_detail_date: {
            title: i18n.t("reportcenter.templates.purchases_by_ro_detail_date"),
            description: "",
            subject: i18n.t("reportcenter.templates.purchases_by_ro_detail_date"),
            key: "purchases_by_ro_detail_date",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "purchases"
          },
          purchases_by_ro_summary_date: {
            title: i18n.t("reportcenter.templates.purchases_by_ro_summary_date"),
            description: "",
            subject: i18n.t("reportcenter.templates.purchases_by_ro_summary_date"),
            key: "purchases_by_ro_summary_date",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "purchases"
          },
          job_costing_ro_date_summary: {
            title: i18n.t("reportcenter.templates.job_costing_ro_date_summary"),
            description: "",
            subject: i18n.t("reportcenter.templates.job_costing_ro_date_summary"),
            key: "job_costing_ro_date_summary",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "jobs"
          },
          job_costing_ro_csr: {
            title: i18n.t("reportcenter.templates.job_costing_ro_csr"),
            description: "",
            subject: i18n.t("reportcenter.templates.job_costing_ro_csr"),
            key: "job_costing_ro_csr",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "jobs"
          },
          job_costing_ro_ins_co: {
            title: i18n.t("reportcenter.templates.job_costing_ro_ins_co"),
            description: "",
            subject: i18n.t("reportcenter.templates.job_costing_ro_ins_co"),
            key: "job_costing_ro_ins_co",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "jobs"
          },
          job_costing_ro_date_detail: {
            title: i18n.t("reportcenter.templates.job_costing_ro_date_detail"),
            description: "",
            subject: i18n.t("reportcenter.templates.job_costing_ro_date_detail"),
            key: "job_costing_ro_date_detail",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "jobs"
          },
          job_costing_ro_estimator: {
            title: i18n.t("reportcenter.templates.job_costing_ro_estimator"),
            description: "",
            subject: i18n.t("reportcenter.templates.job_costing_ro_estimator"),
            key: "job_costing_ro_estimator",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "jobs"
          },
          payments_by_date: {
            title: i18n.t("reportcenter.templates.payments_by_date"),
            subject: i18n.t("reportcenter.templates.payments_by_date"),
            key: "payments_by_date",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.payments"),
              field: i18n.t("payments.fields.date")
            },
            group: "customers"
          },
          payments_by_date_type: {
            title: i18n.t("reportcenter.templates.payments_by_date_type"),
            subject: i18n.t("reportcenter.templates.payments_by_date_type"),
            key: "payments_by_date_type",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.payments"),
              field: i18n.t("payments.fields.date")
            },
            group: "customers"
          },
          payments_by_date_payment: {
            title: i18n.t("reportcenter.templates.payments_by_date_payment"),
            subject: i18n.t("reportcenter.templates.payments_by_date_payment"),
            key: "payments_by_date_payment",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.payments"),
              field: i18n.t("payments.fields.date")
            },
            group: "customers"
          },
          schedule: {
            title: i18n.t("reportcenter.templates.schedule"),
            subject: i18n.t("reportcenter.templates.schedule"),
            key: "schedule",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.appointments"),
              field: i18n.t("appointments.fields.time")
            },
            group: "customers"
          },
          timetickets: {
            title: i18n.t("reportcenter.templates.timetickets"),
            subject: i18n.t("reportcenter.templates.timetickets"),
            key: "timetickets",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.timetickets"),
              field: i18n.t("timetickets.fields.date")
            },
            group: "payroll"
          },
          timetickets_employee: {
            title: i18n.t("reportcenter.templates.timetickets_employee"),
            subject: i18n.t("reportcenter.templates.timetickets_employee"),
            key: "timetickets_employee",
            idtype: "employee",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.timetickets"),
              field: i18n.t("timetickets.fields.date")
            },
            group: "payroll"
          },
          attendance_detail: {
            title: i18n.t("reportcenter.templates.attendance_detail"),
            subject: i18n.t("reportcenter.templates.attendance_detail"),
            key: "attendance_detail",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.timetickets"),
              field: i18n.t("timetickets.fields.date")
            },
            group: "payroll"
          },
          attendance_summary: {
            title: i18n.t("reportcenter.templates.attendance_summary"),
            subject: i18n.t("reportcenter.templates.attendance_summary"),
            key: "attendance_summary",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.timetickets"),
              field: i18n.t("timetickets.fields.date")
            },
            group: "payroll"
          },
          attendance_employee: {
            title: i18n.t("reportcenter.templates.attendance_employee"),
            subject: i18n.t("reportcenter.templates.attendance_employee"),
            key: "attendance_employee",
            idtype: "employee",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.timetickets"),
              field: i18n.t("timetickets.fields.date")
            },
            group: "payroll"
          },
          timetickets_summary: {
            title: i18n.t("reportcenter.templates.timetickets_summary"),
            subject: i18n.t("reportcenter.templates.timetickets_summary"),
            key: "timetickets_summary",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.timetickets"),
              field: i18n.t("timetickets.fields.date")
            },
            group: "payroll"
          },
          estimator_detail: {
            title: i18n.t("reportcenter.templates.estimator_detail"),
            description: "",
            subject: i18n.t("reportcenter.templates.estimator_detail"),
            key: "estimator_detail",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          estimator_summary: {
            title: i18n.t("reportcenter.templates.estimator_summary"),
            description: "",
            subject: i18n.t("reportcenter.templates.estimator_summary"),
            key: "estimator_summary",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          supplement_ratio_ins_co: {
            title: i18n.t("reportcenter.templates.supplement_ratio_ins_co"),
            description: "",
            subject: i18n.t("reportcenter.templates.supplement_ratio_ins_co"),
            key: "supplement_ratio_ins_co",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          credits_not_received_date: {
            title: i18n.t("reportcenter.templates.credits_not_received_date"),
            description: "",
            subject: i18n.t("reportcenter.templates.credits_not_received_date"),
            key: "credits_not_received_date",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "purchases"
          },
          void_ros: {
            title: i18n.t("reportcenter.templates.void_ros"),
            description: "",
            subject: i18n.t("reportcenter.templates.void_ros"),
            key: "void_ros",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_void")
            },
            group: "sales"
          },
          gsr_by_csr: {
            title: i18n.t("reportcenter.templates.gsr_by_csr"),
            description: "",
            subject: i18n.t("reportcenter.templates.gsr_by_csr"),
            key: "gsr_by_csr",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          gsr_by_make: {
            title: i18n.t("reportcenter.templates.gsr_by_make"),
            description: "",
            subject: i18n.t("reportcenter.templates.gsr_by_make"),
            key: "gsr_by_make",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          gsr_by_delivery_date: {
            title: i18n.t("reportcenter.templates.gsr_by_delivery_date"),
            description: "",
            subject: i18n.t("reportcenter.templates.gsr_by_delivery_date"),
            key: "gsr_by_delivery_date",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.actual_delivery")
            },
            group: "sales"
          },
          gsr_by_referral: {
            title: i18n.t("reportcenter.templates.gsr_by_referral"),
            description: "",
            subject: i18n.t("reportcenter.templates.gsr_by_referral"),
            key: "gsr_by_referral",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          gsr_by_ro: {
            title: i18n.t("reportcenter.templates.gsr_by_ro"),
            description: "",
            subject: i18n.t("reportcenter.templates.gsr_by_ro"),
            key: "gsr_by_ro",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          gsr_by_ins_co: {
            title: i18n.t("reportcenter.templates.gsr_by_ins_co"),
            description: "",
            subject: i18n.t("reportcenter.templates.gsr_by_ins_co"),
            key: "gsr_by_ins_co",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          gsr_by_exported_date: {
            title: i18n.t("reportcenter.templates.gsr_by_exported_date"),
            description: "",
            subject: i18n.t("reportcenter.templates.gsr_by_exported_date"),
            key: "gsr_by_exported_date",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_exported")
            },
            group: "sales",
            featureNameRestricted: "export"
          },
          gsr_by_estimator: {
            title: i18n.t("reportcenter.templates.gsr_by_estimator"),
            description: "",
            subject: i18n.t("reportcenter.templates.gsr_by_estimator"),
            key: "gsr_by_estimator",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          gsr_by_category: {
            title: i18n.t("reportcenter.templates.gsr_by_category"),
            description: "",
            subject: i18n.t("reportcenter.templates.gsr_by_category"),
            key: "gsr_by_category",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          gsr_by_ats: {
            title: i18n.t("reportcenter.templates.gsr_by_ats"),
            description: "",
            subject: i18n.t("reportcenter.templates.gsr_by_ats"),
            key: "gsr_by_ats",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          gsr_labor_only: {
            title: i18n.t("reportcenter.templates.gsr_labor_only"),
            description: "",
            subject: i18n.t("reportcenter.templates.gsr_labor_only"),
            key: "gsr_labor_only",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          open_orders: {
            title: i18n.t("reportcenter.templates.open_orders"),
            description: "",
            subject: i18n.t("reportcenter.templates.open_orders"),
            key: "open_orders",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "jobs"
          },
          open_orders_status: {
            title: i18n.t("reportcenter.templates.open_orders_status"),
            description: "",
            subject: i18n.t("reportcenter.templates.open_orders_status"),
            key: "open_orders_status",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "jobs"
          },
          open_orders_csr: {
            title: i18n.t("reportcenter.templates.open_orders_csr"),
            description: "",
            subject: i18n.t("reportcenter.templates.open_orders_csr"),
            key: "open_orders_csr",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "jobs"
          },
          open_orders_specific_csr: {
            title: i18n.t("reportcenter.templates.open_orders_specific_csr"),
            description: "",
            subject: i18n.t("reportcenter.templates.open_orders_specific_csr"),
            key: "open_orders_specific_csr",
            idtype: "employee",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "jobs"
          },
          open_orders_estimator: {
            title: i18n.t("reportcenter.templates.open_orders_estimator"),
            description: "",
            subject: i18n.t("reportcenter.templates.open_orders_estimator"),
            key: "open_orders_estimator",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "jobs"
          },
          open_orders_ins_co: {
            title: i18n.t("reportcenter.templates.open_orders_ins_co"),
            description: "",
            subject: i18n.t("reportcenter.templates.open_orders_ins_co"),
            key: "open_orders_ins_co",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "jobs"
          },
          open_orders_referral: {
            title: i18n.t("reportcenter.templates.open_orders_referral"),
            description: "",
            subject: i18n.t("reportcenter.templates.open_orders_referral"),
            key: "open_orders_referral",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "jobs"
          },
          export_payables: {
            title: i18n.t("reportcenter.templates.export_payables"),
            description: "",
            subject: i18n.t("reportcenter.templates.export_payables"),
            key: "export_payables",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.exportlogs"),
              field: i18n.t("exportlogs.fields.createdat")
            },
            group: "purchases"
          },
          export_payments: {
            title: i18n.t("reportcenter.templates.export_payments"),
            description: "",
            subject: i18n.t("reportcenter.templates.export_payments"),
            key: "export_payments",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.exportlogs"),
              field: i18n.t("exportlogs.fields.createdat")
            },
            group: "customers",
            featureNameRestricted: "export"
          },
          export_receivables: {
            title: i18n.t("reportcenter.templates.export_receivables"),
            description: "",
            subject: i18n.t("reportcenter.templates.export_receivables"),
            key: "export_receivables",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.exportlogs"),
              field: i18n.t("exportlogs.fields.createdat")
            },
            group: "sales",
            featureNameRestricted: "export"
          },
          parts_backorder: {
            title: i18n.t("reportcenter.templates.parts_backorder"),
            description: "",
            subject: i18n.t("reportcenter.templates.parts_backorder"),
            key: "parts_backorder",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.actual_in")
            },
            group: "purchases"
          },
          thank_you_date: {
            title: i18n.t("reportcenter.templates.thank_you_date"),
            description: "",
            subject: i18n.t("reportcenter.templates.thank_you_date"),
            key: "thank_you_date",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "customers"
          },
          unclaimed_hrs: {
            title: i18n.t("reportcenter.templates.unclaimed_hrs"),
            description: "",
            subject: i18n.t("reportcenter.templates.unclaimed_hrs"),
            key: "unclaimed_hrs",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "payroll"
          },
          work_in_progress_jobs_excel: {
            title: i18n.t("reportcenter.templates.work_in_progress_jobs"),
            subject: i18n.t("reportcenter.templates.work_in_progress_jobs"),
            key: "work_in_progress_jobs_excel",
            //idtype: "vendor",
            reporttype: "excel",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "jobs"
          },
          work_in_progress_labour: {
            title: i18n.t("reportcenter.templates.work_in_progress_labour"),
            description: "",
            subject: i18n.t("reportcenter.templates.work_in_progress_labour"),
            key: "work_in_progress_labour",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "jobs",
            featureNameRestricted: "timetickets"
          },
          work_in_progress_committed_labour: {
            title: i18n.t("reportcenter.templates.work_in_progress_committed_labour"),
            description: "",
            subject: i18n.t("reportcenter.templates.work_in_progress_committed_labour"),
            key: "work_in_progress_committed_labour",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "jobs",
            enhanced_payroll: true,
            featureNameRestricted: "timetickets"
          },
          work_in_progress_payables: {
            title: i18n.t("reportcenter.templates.work_in_progress_payables"),
            description: "",
            subject: i18n.t("reportcenter.templates.work_in_progress_payables"),
            key: "work_in_progress_payables",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "jobs",
            featureNameRestricted: "bills"
          },
          lag_time: {
            title: i18n.t("reportcenter.templates.lag_time"),
            description: "",
            subject: i18n.t("reportcenter.templates.lag_time"),
            key: "lag_time",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "jobs"
          },
          parts_not_recieved: {
            title: i18n.t("reportcenter.templates.parts_not_recieved"),
            description: "",
            subject: i18n.t("reportcenter.templates.parts_not_recieved"),
            key: "parts_not_recieved",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.parts_orders"),
              field: i18n.t("parts_orders.fields.order_date")
            },
            group: "purchases"
          },
          parts_not_recieved_vendor: {
            title: i18n.t("reportcenter.templates.parts_not_recieved_vendor"),
            description: "",
            subject: i18n.t("reportcenter.templates.parts_not_recieved_vendor"),
            key: "parts_not_recieved_vendor",
            idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.parts_orders"),
              field: i18n.t("parts_orders.fields.order_date")
            },
            group: "purchases"
          },
          scoreboard_detail: {
            title: i18n.t("reportcenter.templates.scoreboard_detail"),
            description: "",
            subject: i18n.t("reportcenter.templates.scoreboard_detail"),
            key: "scoreboard_detail",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.scoreboard"),
              field: i18n.t("scoreboard.fields.date")
            },
            group: "payroll"
          },
          scoreboard_summary: {
            title: i18n.t("reportcenter.templates.scoreboard_summary"),
            description: "",
            subject: i18n.t("reportcenter.templates.scoreboard_summary"),
            key: "scoreboard_summary",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.scoreboard"),
              field: i18n.t("scoreboard.fields.date")
            },
            group: "payroll"
          },
          anticipated_revenue: {
            title: i18n.t("reportcenter.templates.anticipated_revenue"),
            description: "",
            subject: i18n.t("reportcenter.templates.anticipated_revenue"),
            key: "anticipated_revenue",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.scheduled_completion") // Also date invoice.
            },
            group: "sales"
          },
          csi: {
            title: i18n.t("reportcenter.templates.csi"),
            description: "",
            subject: i18n.t("reportcenter.templates.csi"),
            key: "csi",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.csi"),
              field: i18n.t("csi.fields.created_at") // Also date invoice.
            },
            group: "customers",
            featureNameRestricted: "csi"
          },
          estimates_written_converted: {
            title: i18n.t("reportcenter.templates.estimates_written_converted"),
            description: "",
            subject: i18n.t("reportcenter.templates.estimates_written_converted"),
            key: "estimates_written_converted",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open") + "/" + i18n.t("jobs.fields.date_invoiced") // Also date invoice.
            },
            group: "sales"
          },
          credits_not_received_date_vendorid: {
            title: i18n.t("reportcenter.templates.credits_not_received_date_vendorid"),
            subject: i18n.t("reportcenter.templates.credits_not_received_date_vendorid"),
            key: "credits_not_received_date_vendorid",
            idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "purchases"
          },
          jobs_reconcile: {
            title: i18n.t("reportcenter.templates.jobs_reconcile"),
            subject: i18n.t("reportcenter.templates.jobs_reconcile"),
            key: "jobs_reconcile",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "jobs",
            featureNameRestricted: "bills"
          },
          parts_received_not_scheduled: {
            title: i18n.t("reportcenter.templates.parts_received_not_scheduled"),
            subject: i18n.t("reportcenter.templates.parts_received_not_scheduled"),
            key: "parts_received_not_scheduled",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "jobs",
            featureNameRestricted: "bills"
          },
          psr_by_make: {
            title: i18n.t("reportcenter.templates.psr_by_make"),
            subject: i18n.t("reportcenter.templates.psr_by_make"),
            key: "psr_by_make",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "sales"
          },
          cycle_time_analysis: {
            title: i18n.t("reportcenter.templates.cycle_time_analysis"),
            subject: i18n.t("reportcenter.templates.cycle_time_analysis"),
            key: "cycle_time_analysis",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.actual_completion")
            },
            group: "jobs"
          },
          returns_grouped_by_vendor_summary: {
            title: i18n.t("reportcenter.templates.returns_grouped_by_vendor_summary"),
            subject: i18n.t("reportcenter.templates.returns_grouped_by_vendor_summary"),
            key: "returns_grouped_by_vendor_summary",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.parts_orders"),
              field: i18n.t("parts_orders.fields.order_date")
            },
            group: "jobs",
            featureNameRestricted: "bills"
          },
          returns_grouped_by_vendor_detailed: {
            title: i18n.t("reportcenter.templates.returns_grouped_by_vendor_detailed"),
            subject: i18n.t("reportcenter.templates.returns_grouped_by_vendor_detailed"),
            key: "returns_grouped_by_vendor_detailed",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.parts_orders"),
              field: i18n.t("parts_orders.fields.order_date")
            },
            group: "jobs",
            featureNameRestricted: "bills"
          },
          scheduled_parts_list: {
            title: i18n.t("reportcenter.templates.scheduled_parts_list"),
            subject: i18n.t("reportcenter.templates.scheduled_parts_list"),
            key: "scheduled_parts_list",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.scheduled_in")
            },
            group: "jobs"
          },
          jobs_completed_not_invoiced: {
            title: i18n.t("reportcenter.templates.jobs_completed_not_invoiced"),
            subject: i18n.t("reportcenter.templates.jobs_completed_not_invoiced"),
            key: "jobs_completed_not_invoiced",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "jobs"
          },
          jobs_invoiced_not_exported: {
            title: i18n.t("reportcenter.templates.jobs_invoiced_not_exported"),
            subject: i18n.t("reportcenter.templates.jobs_invoiced_not_exported"),
            key: "jobs_invoiced_not_exported",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "jobs",
            featureNameRestricted: "export"
          },
          purchase_return_ratio_grouped_by_vendor_detail: {
            title: i18n.t("reportcenter.templates.purchase_return_ratio_grouped_by_vendor_detail"),
            subject: i18n.t("reportcenter.templates.purchase_return_ratio_grouped_by_vendor_detail"),
            key: "purchase_return_ratio_grouped_by_vendor_detail",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.bills"),
              field: i18n.t("bills.fields.date")
            },
            group: "purchases"
          },
          purchase_return_ratio_grouped_by_vendor_summary: {
            title: i18n.t("reportcenter.templates.purchase_return_ratio_grouped_by_vendor_summary"),
            subject: i18n.t("reportcenter.templates.purchase_return_ratio_grouped_by_vendor_summary"),
            key: "purchase_return_ratio_grouped_by_vendor_summary",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.bills"),
              field: i18n.t("bills.fields.date")
            },
            group: "purchases"
          },
          production_over_time: {
            title: i18n.t("reportcenter.templates.production_over_time"),
            subject: i18n.t("reportcenter.templates.production_over_time"),
            key: "production_over_time",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.actual_in")
            },
            group: "jobs"
          },
          customer_list: {
            title: i18n.t("reportcenter.templates.customer_list"),
            subject: i18n.t("reportcenter.templates.customer_list"),
            key: "customer_list",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "customers"
          },
          exported_gsr_by_ro: {
            title: i18n.t("reportcenter.templates.exported_gsr_by_ro"),
            subject: i18n.t("reportcenter.templates.exported_gsr_by_ro"),
            key: "exported_gsr_by_ro",
            //idtype: "vendor",
            reporttype: "excel",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_exported")
            },
            group: "sales",
            featureNameRestricted: "export"
          },
          exported_gsr_by_ro_labor: {
            title: i18n.t("reportcenter.templates.exported_gsr_by_ro_labor"),
            subject: i18n.t("reportcenter.templates.exported_gsr_by_ro_labor"),
            key: "exported_gsr_by_ro_labor",
            //idtype: "vendor",
            reporttype: "excel",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_exported")
            },
            group: "sales",
            featureNameRestricted: "export"
          },
          jobs_scheduled_completion: {
            title: i18n.t("reportcenter.templates.jobs_scheduled_completion"),
            subject: i18n.t("reportcenter.templates.jobs_scheduled_completion"),
            key: "jobs_scheduled_completion",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.scheduled_completion")
            },
            group: "jobs"
          },
          committed_timetickets: {
            title: i18n.t("reportcenter.templates.committed_timetickets"),
            subject: i18n.t("reportcenter.templates.committed_timetickets"),
            key: "committed_timetickets",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.timetickets"),
              field: i18n.t("timetickets.fields.committed_at")
            },
            group: "payroll",
            enhanced_payroll: true
          },
          committed_timetickets_employee: {
            title: i18n.t("reportcenter.templates.committed_timetickets_employee"),
            subject: i18n.t("reportcenter.templates.committed_timetickets_employee"),
            key: "committed_timetickets_employee",
            idtype: "employee",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.timetickets"),
              field: i18n.t("timetickets.fields.committed_at")
            },
            group: "payroll",
            enhanced_payroll: true
          },
          committed_timetickets_summary: {
            title: i18n.t("reportcenter.templates.committed_timetickets_summary"),
            subject: i18n.t("reportcenter.templates.committed_timetickets_summary"),
            key: "committed_timetickets_summary",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.timetickets"),
              field: i18n.t("timetickets.fields.committed_at")
            },
            group: "payroll",
            enhanced_payroll: true
          },
          lost_sales: {
            title: i18n.t("reportcenter.templates.lost_sales"),
            subject: i18n.t("reportcenter.templates.lost_sales"),
            key: "lost_sales",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_lost_sale")
            },
            group: "customers"
          },
          load_level: {
            title: i18n.t("reportcenter.templates.load_level"),
            subject: i18n.t("reportcenter.templates.load_level"),
            key: "load_level",
            //idtype: "vendor",
            disabled: false,
            datedisable: true,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "jobs",
            enhanced_payroll: true
          },
          open_orders_excel: {
            title: i18n.t("reportcenter.templates.open_orders_excel"),
            subject: i18n.t("reportcenter.templates.open_orders_excel"),
            key: "open_orders_excel",
            //idtype: "vendor",
            reporttype: "excel",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "jobs"
          },
          ar_aging: {
            title: i18n.t("reportcenter.templates.ar_aging"),
            subject: i18n.t("reportcenter.templates.ar_aging"),
            key: "ar_aging",
            //idtype: "vendor",
            disabled: false,
            datedisable: true,
            group: "customers"
          },
          job_lifecycle_date_detail: {
            title: i18n.t("reportcenter.templates.job_lifecycle_date_detail"),
            subject: i18n.t("reportcenter.templates.job_lifecycle_date_detail"),
            key: "job_lifecycle_date_detail",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "jobs"
            //featureNameRestricted: "lifecycle"
          },
          job_lifecycle_date_summary: {
            title: i18n.t("reportcenter.templates.job_lifecycle_date_summary"),
            subject: i18n.t("reportcenter.templates.job_lifecycle_date_summary"),
            key: "job_lifecycle_date_summary",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_invoiced")
            },
            group: "jobs"
            //featureNameRestricted: "lifecycle"
          },
          tasks_date: {
            title: i18n.t("reportcenter.templates.tasks_date"),
            subject: i18n.t("reportcenter.templates.tasks_date"),
            key: "tasks_date",
            //idtype: "vendor",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.tasks"),
              field: i18n.t("tasks.fields.created_at")
            },
            group: "jobs"
          },
          tasks_date_employee: {
            title: i18n.t("reportcenter.templates.tasks_date_employee"),
            subject: i18n.t("reportcenter.templates.tasks_date_employee"),
            key: "tasks_date_employee",
            idtype: "employee",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.tasks"),
              field: i18n.t("tasks.fields.created_at")
            },
            group: "jobs"
          },
          adp_payroll_flat: {
            title: i18n.t("reportcenter.templates.adp_payroll_flat"),
            subject: i18n.t("reportcenter.templates.adp_payroll_flat"),
            key: "adp_payroll_flat",
            reporttype: "text",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.timetickets"),
              field: i18n.t("timetickets.fields.committed_at")
            },
            group: "payroll",
            adp_payroll: true
          },
          adp_payroll_straight: {
            title: i18n.t("reportcenter.templates.adp_payroll_straight"),
            subject: i18n.t("reportcenter.templates.adp_payroll_straight"),
            key: "adp_payroll_straight",
            reporttype: "text",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.timetickets"),
              field: i18n.t("timetickets.fields.date")
            },
            group: "payroll",
            adp_payroll: true
          },
          purchase_return_ratio_excel: {
            title: i18n.t("reportcenter.templates.purchase_return_ratio_excel"),
            subject: i18n.t("reportcenter.templates.purchase_return_ratio_excel"),
            key: "purchase_return_ratio_excel",
            //idtype: "vendor",
            reporttype: "excel",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.bills"),
              field: i18n.t("bills.fields.date")
            },
            group: "purchases"
          },
          total_loss_jobs: {
            title: i18n.t("reportcenter.templates.total_loss_jobs"),
            subject: i18n.t("reportcenter.templates.total_loss_jobs"),
            key: "total_loss_jobs",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.jobs"),
              field: i18n.t("jobs.fields.date_open")
            },
            group: "jobs"
          },
          purchases_by_date_excel: {
            title: i18n.t("reportcenter.templates.purchases_by_date_excel"),
            subject: i18n.t("reportcenter.templates.purchases_by_date_excel"),
            key: "purchases_by_date_excel",
            reporttype: "excel",
            disabled: false,
            rangeFilter: {
              object: i18n.t("reportcenter.labels.objects.bills"),
              field: i18n.t("bills.fields.date")
            },
            group: "purchases"
          },
        }
      : {}),
    ...(!type || type === "courtesycarcontract"
      ? {
          courtesy_car_contract: {
            title: i18n.t("printcenter.courtesycarcontract.courtesy_car_contract"),
            description: "",
            subject: i18n.t("printcenter.courtesycarcontract.courtesy_car_contract"),
            key: "courtesy_car_contract",
            disabled: false
          },
          courtesy_car_terms: {
            title: i18n.t("printcenter.courtesycarcontract.courtesy_car_terms"),
            description: "",
            subject: i18n.t("printcenter.courtesycarcontract.courtesy_car_terms"),
            key: "courtesy_car_terms",
            disabled: false
          },
          courtesy_car_impound: {
            title: i18n.t("printcenter.courtesycarcontract.courtesy_car_impound"),
            description: "",
            subject: i18n.t("printcenter.courtesycarcontract.courtesy_car_impound"),
            key: "courtesy_car_impound",
            disabled: false
          }
        }
      : {}),
    ...(!type || type === "courtesycar"
      ? {
          courtesy_car_inventory: {
            title: i18n.t("printcenter.courtesycarcontract.courtesy_car_inventory"),
            description: "",
            subject: i18n.t("printcenter.courtesycarcontract.courtesy_car_inventory"),
            key: "courtesy_car_inventory",
            disabled: false
          }
        }
      : {}),
    ...(!type || type === "bill"
      ? {
          inhouse_invoice: {
            title: i18n.t("printcenter.bills.inhouse_invoice"),
            description: "",
            subject: i18n.t("printcenter.bills.inhouse_invoice"),
            key: "inhouse_invoice",
            disabled: false
          }
        }
      : {}),
    ...(!type || type === "timeticket"
      ? {
          // timetickets: {
          //   title: i18n.t("printcenter.timetickets.timetickets"),
          //   description: "",
          //   subject: `${i18n.t("printcenter.timetickets.timetickets")} - ${
          //     context && context.job && context.job.ro_number
          //   }`,
          //   key: "timetickets",
          //   disabled: false,
          // },
        }
      : {}),
    ...(!type || type === "messaging"
      ? {
          conversation_list: {
            title: i18n.t("messaging.render.conversation_list"),
            description: "",
            subject: i18n.t("messaging.render.conversation_list"),
            key: "conversation_list",
            disabled: false
          }
        }
      : {}),
    ...(!type || type === "vendor"
      ? {
          purchases_by_vendor_detailed: {
            title: i18n.t("printcenter.vendors.purchases_by_vendor_detailed"),
            description: "",
            subject: i18n.t("printcenter.vendors.purchases_by_vendor_detailed"),
            key: "purchases_by_vendor_detailed",
            disabled: false
          },
          purchases_by_vendor_summary: {
            title: i18n.t("printcenter.vendors.purchases_by_vendor_summary"),
            description: "",
            subject: i18n.t("printcenter.vendors.purchases_by_vendor_summary"),
            key: "purchases_by_vendor_summary",
            disabled: false
          }
        }
      : {}),
    ...(!type || type === "production"
      ? {
          production_by_last_name: {
            title: i18n.t("reportcenter.templates.production_by_last_name"),
            description: "",
            subject: i18n.t("reportcenter.templates.production_by_last_name"),
            key: "production_by_last_name",
            //idtype: "vendor",
            disabled: false
          },
          production_by_repair_status: {
            title: i18n.t("reportcenter.templates.production_by_repair_status"),
            description: "",
            subject: i18n.t("reportcenter.templates.production_by_repair_status"),
            key: "production_by_repair_status",
            //idtype: "vendor",
            disabled: false
          },
          production_by_target_date: {
            title: i18n.t("reportcenter.templates.production_by_target_date"),
            description: "",
            subject: i18n.t("reportcenter.templates.production_by_target_date"),
            key: "production_by_target_date",
            //idtype: "vendor",
            disabled: false
          },
          production_by_ro: {
            title: i18n.t("reportcenter.templates.production_by_ro"),
            description: "",
            subject: i18n.t("reportcenter.templates.production_by_ro"),
            key: "production_by_ro",
            //idtype: "vendor",
            disabled: false
          },
          production_by_csr: {
            title: i18n.t("reportcenter.templates.production_by_csr"),
            description: "",
            subject: i18n.t("reportcenter.templates.production_by_csr"),
            key: "production_by_csr",
            //idtype: "vendor",
            disabled: false
          },
          production_by_category: {
            title: i18n.t("reportcenter.templates.production_by_category"),
            description: "",
            subject: i18n.t("reportcenter.templates.production_by_category"),
            key: "production_by_category",
            //idtype: "vendor",
            disabled: false
          },
          production_by_technician: {
            title: i18n.t("reportcenter.templates.production_by_technician"),
            description: "",
            subject: i18n.t("reportcenter.templates.production_by_technician"),
            key: "production_by_technician",
            //idtype: "vendor",
            disabled: false
          },
          production_not_production_status: {
            title: i18n.t("reportcenter.templates.production_not_production_status"),
            description: "",
            subject: i18n.t("reportcenter.templates.production_not_production_status"),
            key: "production_not_production_status",
            //idtype: "vendor",
            disabled: false
          }
        }
      : {}),
    ...(!type || type === "special"
      ? {
          ca_bc_etf_table: {
            title: i18n.t("printcenter.payments.ca_bc_etf_table"),
            description: "",
            subject: i18n.t("printcenter.payments.ca_bc_etf_table"),
            key: "ca_bc_etf_table",
            disabled: false
          },
          exported_payroll: {
            title: i18n.t("printcenter.payments.exported_payroll"),
            description: "",
            subject: i18n.t("printcenter.payments.exported_payroll"),
            key: "exported_payroll",
            disabled: false
          },
          attendance_detail_csv: {
            title: i18n.t("printcenter.special.attendance_detail_csv"),
            description: "",
            subject: i18n.t("printcenter.special.attendance_detail_csv"),
            key: "attendance_detail_csv",
            disabled: false
          },
          production_by_technician_one: {
            title: i18n.t("reportcenter.templates.production_by_technician_one"),
            description: "",
            subject: i18n.t("reportcenter.templates.production_by_technician_one"),
            key: "production_by_technician_one",
            //idtype: "vendor",
            disabled: false
          },
          production_by_category_one: {
            title: i18n.t("reportcenter.templates.production_by_category_one"),
            description: "",
            subject: i18n.t("reportcenter.templates.production_by_category_one"),
            key: "production_by_category_one",
            //idtype: "vendor",
            disabled: false
          },
          production_by_repair_status_one: {
            title: i18n.t("reportcenter.templates.production_by_repair_status_one"),
            description: "",
            subject: i18n.t("reportcenter.templates.production_by_repair_status_one"),
            key: "production_by_repair_status_one",
            //idtype: "vendor",
            disabled: false
          }
        }
      : {})
  };
};

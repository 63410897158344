const UserActionTypes = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  GOOGLE_SIGN_IN_START: "GOOGLE_SIGN_IN_START",
  SIGN_IN_SUCCESS: "SIGN_IN_SUCCESS",
  SIGN_IN_FAILURE: "SIGN_IN_FAILURE",
  EMAIL_SIGN_IN_START: "EMAIL_SIGN_IN_START",
  CHECK_USER_SESSION: "CHECK_USER_SESSION",
  SIGN_OUT_START: "SIGN_OUT_START",
  SIGN_OUT_SUCCESS: "SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILURE: "SIGN_OUT_FAILURE",
  EMAIL_SIGN_UP_START: "EMAIL_SIGN_UP_START",
  EMAIL_SIGN_UP_SUCCESS: "EMAIL_SIGN_UP_SUCCESS",
  EMAIL_SIGN_UP_FAILURE: "EMAIL_SIGN_UP_FAILURE",
  UNAUTHORIZED_USER: "UNAUTHORIZED_USER",
  SET_USER_LANGUAGE: "SET_USER_LANGUAGE",
  UPDATE_USER_DETAILS: "UPDATE_USER_DETAILS",
  UPDATE_USER_DETAILS_SUCCESS: "UPDATE_USER_DETAILS_SUCCESS",
  SET_SHOP_DETAILS: "SET_SHOP_DETAILS",
  SET_INSTANCE_ID: "SET_INSTANCE_ID",
  CHECK_INSTANCE_ID: "CHECK_INSTANCE_ID",
  SET_INSTANCE_CONFLICT: "SET_INSTANCE_CONFLICT",
  SET_LOCAL_FINGERPRINT: "SET_LOCAL_FINGERPRINT",
  SEND_PASSWORD_RESET_EMAIL_START: "SEND_PASSWORD_RESET_EMAIL_START",
  SEND_PASSWORD_RESET_EMAIL_START_AGAIN: "SEND_PASSWORD_RESET_EMAIL_START_AGAIN",
  SEND_PASSWORD_RESET_EMAIL_FAILURE: "SEND_PASSWORD_RESET_EMAIL_FAILURE",
  SEND_PASSWORD_RESET_EMAIL_SUCCESS: "SEND_PASSWORD_RESET_EMAIL_SUCCESS",
  VALIDATE_PASSWORD_RESET_START: "VALIDATE_PASSWORD_RESET_START",
  VALIDATE_PASSWORD_RESET_SUCCESS: "VALIDATE_PASSWORD_RESET_SUCCESS",
  VALIDATE_PASSWORD_RESET_FAILURE: "VALIDATE_PASSWORD_RESET_FAILURE",
  SET_AUTH_LEVEL: "SET_AUTH_LEVEL",
  CHECK_ACTION_CODE_START: "CHECK_ACTION_CODE_START",
  CHECK_ACTION_CODE_SUCCESS: "CHECK_ACTION_CODE_SUCCESS",
  CHECK_ACTION_CODE_FAILURE: "CHECK_ACTION_CODE_FAILURE",
  SET_CURRENT_EULA: "SET_CURRENT_EULA",
  EULA_ACCEPTED: "EULA_ACCEPTED",
  SET_IMEX_SHOP_ID: "SET_IMEX_SHOP_ID"
};
export default UserActionTypes;

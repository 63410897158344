import React from "react";
import { Spin } from "antd";
import "./loading-spinner.styles.scss";

export default function LoadingSpinner({ loading = true, message, ...props }) {
  return (
    <div className="loading-spinner">
      <Spin
        spinning={loading}
        size="large"
        style={{
          position: "relative",
          alignContent: "center"
        }}
        {...(props.children ? { tip: message ? message : null } : {})}
        delay={200}
        // TODO: Client Update - tip only works when there are actually children, and this component is used in a lot of places where there are no children
      >
        {props.children}
      </Spin>
    </div>
  );
}

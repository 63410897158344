import ApplicationActionTypes from "./application.types";

export const startLoading = () => ({
  type: ApplicationActionTypes.START_LOADING
});

export const endLoading = (options) => ({
  type: ApplicationActionTypes.END_LOADING,
  payload: options
});

export const setBreadcrumbs = (breadcrumbs) => ({
  type: ApplicationActionTypes.SET_BREAD_CRUMBS,
  payload: breadcrumbs
});

export const calculateScheduleLoad = (rangeEnd) => ({
  type: ApplicationActionTypes.CALCULATE_SCHEDULE_LOAD,
  payload: rangeEnd
});

export const scheduleLoadSuccess = (load) => ({
  type: ApplicationActionTypes.CALCULATE_SCHEDULE_LOAD_SUCCESS,
  payload: load
});

export const scheduleLoadFailure = (error) => ({
  type: ApplicationActionTypes.CALCULATE_SCHEDULE_LOAD_FAILURE,
  payload: error
});

export const addRecentItem = (item) => ({
  type: ApplicationActionTypes.ADD_RECENT_ITEM,
  payload: item
});

export const setSelectedHeader = (key) => ({
  type: ApplicationActionTypes.SET_SELECTED_HEADER,
  payload: key
});

export const setJobReadOnly = (bool) => ({
  type: ApplicationActionTypes.SET_JOB_READONLY,
  payload: bool
});

export const setPartnerVersion = (version) => ({
  type: ApplicationActionTypes.SET_PARTNER_VERSION,
  payload: version
});

export const setOnline = (isOnline) => ({
  type: ApplicationActionTypes.SET_ONLINE_STATUS,
  payload: isOnline
});

export const insertAuditTrail = ({ jobid, billid, operation, type }) => ({
  type: ApplicationActionTypes.INSERT_AUDIT_TRAIL,
  payload: { jobid, billid, operation, type }
});
export const setProblemJobs = (problemJobs) => ({
  type: ApplicationActionTypes.SET_PROBLEM_JOBS,
  payload: problemJobs
});

export const setUpdateAvailable = (isUpdateAvailable) => ({
  type: ApplicationActionTypes.SET_UPDATE_AVAILABLE,
  payload: isUpdateAvailable
});

export const addAlerts = (alerts) => ({
  type: ApplicationActionTypes.ADD_ALERTS,
  payload: alerts
});

export const setWssStatus = (status) => ({
  type: ApplicationActionTypes.SET_WSS_STATUS,
  payload: status
});

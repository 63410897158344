import { gql } from "@apollo/client";

export const UNREAD_CONVERSATION_COUNT = gql`
  query UNREAD_CONVERSATION_COUNT {
    messages_aggregate(where: { read: { _eq: false }, isoutbound: { _eq: false } }) {
      aggregate {
        count
      }
    }
  }
`;

export const CONVERSATION_LIST_QUERY = gql`
  query CONVERSATION_LIST_QUERY($offset: Int!) {
    conversations(order_by: { updated_at: desc }, limit: 50, offset: $offset, where: { archived: { _eq: false } }) {
      phone_num
      id
      updated_at
      unreadcnt
      archived
      label
      messages_aggregate(where: { read: { _eq: false }, isoutbound: { _eq: false } }) {
        aggregate {
          count
        }
      }
      job_conversations {
        job {
          id
          ro_number
          ownr_fn
          ownr_ln
          ownr_co_nm
        }
      }
    }
  }
`;

export const CONVERSATION_SUBSCRIPTION_BY_PK = gql`
  subscription CONVERSATION_SUBSCRIPTION_BY_PK($conversationId: uuid!) {
    messages(order_by: { created_at: asc_nulls_first }, where: { conversationid: { _eq: $conversationId } }) {
      id
      status
      text
      isoutbound
      image
      image_path
      userid
      created_at
      read
    }
  }
`;

export const GET_CONVERSATION_DETAILS = gql`
  query GET_CONVERSATION_DETAILS($conversationId: uuid!) {
    conversations_by_pk(id: $conversationId) {
      id
      phone_num
      archived
      updated_at
      unreadcnt
      label
      job_conversations {
        jobid
        conversationid
        job {
          id
          ownr_fn
          ownr_ln
          ownr_co_nm
          ro_number
        }
      }
      messages(order_by: { created_at: asc_nulls_first }) {
        id
        status
        text
        isoutbound
        image
        image_path
        userid
        created_at
        read
      }
    }
  }
`;

export const CONVERSATION_ID_BY_PHONE = gql`
  query CONVERSATION_ID_BY_PHONE($phone: String!) {
    conversations(where: { phone_num: { _eq: $phone } }) {
      id
      phone_num
      archived
      label
      unreadcnt
      created_at
      job_conversations {
        jobid
        conversationid
        job {
          id
          ownr_fn
          ownr_ln
          ownr_co_nm
          ro_number
        }
      }
      messages_aggregate(where: { read: { _eq: false }, isoutbound: { _eq: false } }) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const CREATE_CONVERSATION = gql`
  mutation CREATE_CONVERSATION($conversation: [conversations_insert_input!]!) {
    insert_conversations(objects: $conversation) {
      returning {
        id
        phone_num
        archived
        label
        unreadcnt
        job_conversations {
          jobid
          conversationid
          job {
            id
            ownr_fn
            ownr_ln
            ownr_co_nm
            ro_number
          }
        }
        messages_aggregate(where: { read: { _eq: false }, isoutbound: { _eq: false } }) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const TOGGLE_CONVERSATION_ARCHIVE = gql`
  mutation TOGGLE_CONVERSATION_ARCHIVE($id: uuid!, $archived: Boolean) {
    update_conversations_by_pk(pk_columns: { id: $id }, _set: { archived: $archived }) {
      archived
      id
    }
  }
`;

export const UPDATE_CONVERSATION_LABEL = gql`
  mutation UPDATE_CONVERSATION_LABEL($id: uuid!, $label: String) {
    update_conversations_by_pk(pk_columns: { id: $id }, _set: { label: $label }) {
      label
      id
    }
  }
`;

import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { withReduxStateSync } from "redux-state-sync";
import applicationReducer from "./application/application.reducer";
import emailReducer from "./email/email.reducer";
import mediaReducer from "./media/media.reducer";
import messagingReducer from "./messaging/messaging.reducer";
import modalsReducer from "./modals/modals.reducer";
import techReducer from "./tech/tech.reducer";
import userReducer from "./user/user.reducer";
import trelloReducer from "./trello/trello.reducer";

// const persistConfig = {
//   key: "root",
//   storage,
//   whitelist: ["messaging", "tech", "application"],
//   blacklist: ["user", "email", "modals"],
// };

const applicationPersistConfig = {
  key: "v",
  storage: storage,
  whitelist: ["recentItems"]
};

const rootReducer = combineReducers({
  user: userReducer,
  messaging: messagingReducer,
  email: emailReducer,
  modals: modalsReducer,
  application: persistReducer(applicationPersistConfig, applicationReducer),
  tech: techReducer,
  media: mediaReducer,
  trello: trelloReducer
});

export default withReduxStateSync(rootReducer);

import Dinero from "dinero.js";
import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectBodyshop } from "../../redux/user/user.selectors";
import { HasFeatureAccess } from "./feature-wrapper.component";
import { DateTimeFormatterFunction } from "../../utils/DateFormatter";

const mapStateToProps = createStructuredSelector({
  bodyshop: selectBodyshop
});
const blurringProps = {
  filter: "blur(4px)",
  webkitUserSelect: "none",
  msUserSelect: "none",
  mozUserSelect: "none",
  userSelect: "none",
  pointerEvents: "none"
};

export function BlurWrapper({
  bodyshop,
  featureName,
  styleProp = "style",
  valueProp = "value",
  overrideValue = true,
  overrideValueFunction,
  children,
  debug,
  bypass
}) {
  if (import.meta.env.DEV) {
    if (!ValidateFeatureName(featureName)) console.trace("*** INVALID FEATURE NAME", featureName);
  }

  if (debug) {
    console.trace("*** DEBUG MODE", featureName);
    console.log("*** HAS FEATURE ACCESS?", featureName, HasFeatureAccess({ featureName, bodyshop }));
    console.log(
      "***LOG  ~ All Blur Wrapper Props ",
      styleProp,
      valueProp,
      overrideValue,
      overrideValueFunction,
      children,
      debug,
      bypass
    );
  }

  if (bypass) {
    if (import.meta.env.DEV) {
      console.trace("*** Blur Wrapper BYPASS USED", featureName);
    }
    return children;
  }

  if (!HasFeatureAccess({ featureName, bodyshop })) {
    const childrenWithBlurProps = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        //Clone the child, and spread in our props to overwrite it.
        let newValueProp;
        if (!overrideValue) {
          newValueProp = child.props[valueProp];
        } else {
          if (typeof overrideValueFunction === "function") {
            newValueProp = overrideValueFunction();
          } else if (typeof overrideValueFunction === "string" && overrideValueFunction === "RandomDinero") {
            newValueProp = RandomDinero();
          } else if (typeof overrideValueFunction === "string" && overrideValueFunction === "RandomAmount") {
            newValueProp = RandomAmount();
          } else if (
            typeof overrideValueFunction === "string" &&
            overrideValueFunction.startsWith("RandomSmallString")
          ) {
            newValueProp = RandomSmallString(overrideValueFunction.split(":")[1] || 3); //Default back to 3 words, otherwise use the string.
          } else if (typeof overrideValueFunction === "string" && overrideValueFunction.startsWith("RandomDate")) {
            newValueProp = RandomDate();
          } else {
            newValueProp = "This is some random text. Nothing interesting here.";
          }
        }

        return React.cloneElement(child, {
          [valueProp]: newValueProp,
          [styleProp]: { ...child.props[styleProp], ...blurringProps }
        });
      }
      return child;
    });

    return childrenWithBlurProps;
  }

  return children;
}
export default connect(mapStateToProps, null)(BlurWrapper);

function RandomDinero() {
  return Dinero({ amount: Math.round(Math.exp(Math.random() * 10, 2)) }).toFormat();
}
function RandomAmount() {
  return Math.round(Math.exp(Math.random() * 10));
}

function RandomSmallString(maxWords = 3) {
  const words = ["lorem", "ipsum", "dolor", "sit", "amet", "consectetur", "adipiscing", "elit"];
  const wordCount = Math.floor(Math.random() * maxWords) + 1; // Random number between 1 and 3
  let result = [];
  for (let i = 0; i < wordCount; i++) {
    const randomIndex = Math.floor(Math.random() * words.length);
    result.push(words[randomIndex]);
  }
  return result.join(" ");
}
function RandomDate() {
  return DateTimeFormatterFunction(new Date(Math.floor(Math.random() * 1000000000000)));
}

const featureNameList = [
  "mobile",
  "allAccess",
  //"audit", //Removing 2024-12-13. Keeping as default feature.
  "timetickets",
  "payments",
  "partsorders",
  "bills",
  "export",
  "csi",
  "courtesycars",
  "media",
  "visualboard",
  "scoreboard",
  "techconsole",
  "checklist",
  "smartscheduling",
  "roguard",
  "dashboard"
  //"lifecycle" //Removing 2024-12-13. Keeping as default feature.
];

export function ValidateFeatureName(featureName) {
  return featureNameList.includes(featureName);
}

import { getAnalytics, logEvent } from "@firebase/analytics";
import { initializeApp } from "@firebase/app";
import { getAuth, updatePassword, updateProfile } from "@firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";
import { store } from "../redux/store";

const config = JSON.parse(import.meta.env.VITE_APP_FIREBASE_CONFIG);
initializeApp(config);

export const auth = getAuth();
export const firestore = getFirestore();
export const analytics = getAnalytics();

//export default firebase;
export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};

export const updateCurrentUser = (userDetails) => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      updateProfile(userAuth, userDetails).then((r) => {
        unsubscribe();
        resolve(userAuth);
      });
    }, reject);
  });
};

export const updateCurrentPassword = async (password) => {
  const currentUser = await getCurrentUser();

  return updatePassword(currentUser, password);
};
let messaging;
try {
  messaging = getMessaging();
} catch (error) {
  console.log(error);
}

export { messaging };

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: import.meta.env.VITE_APP_FIREBASE_PUBLIC_VAPID_KEY
  })
    .then((currentToken) => {
      if (currentToken) {
        window.sessionStorage.setItem("fcmtoken", currentToken);
        // Perform any other necessary action with the token
      } else {
        // Show permission request UI
        console.log("No registration token available. Request permission to generate one.");
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

onMessage(messaging, (payload) => {
  console.log("FCM Message received. ", payload);
  // ...
});

export const logImEXEvent = (eventName, additionalParams, stateProp = null) => {
  const state = stateProp || store.getState();
  const eventParams = {
    shop: (state.user && state.user.bodyshop && state.user.bodyshop.shopname) || null,
    user: (state.user && state.user.currentUser && state.user.currentUser.email) || null,
    ...additionalParams
  };
  // axios.post("/ioevent", {
  //   useremail: (state.user && state.user.currentUser && state.user.currentUser.email) || null,
  //   bodyshopid: (state.user && state.user.bodyshop && state.user.bodyshop.id) || null,
  //   operationName: eventName,
  //   variables: additionalParams,
  //   dbevent: false,
  //   env: `master-AIO|${import.meta.env.VITE_APP_GIT_SHA_DATE}`
  // });
  // console.log(
  //   "%c[Analytics]",
  //   "background-color: green ;font-weight:bold;",
  //   eventName,
  //   eventParams
  // );
  logEvent(analytics, eventName, eventParams);
};

import { all, call } from "redux-saga/effects";

import { userSagas } from "./user/user.sagas";
import { messagingSagas } from "./messaging/messaging.sagas";
import { emailSagas } from "./email/email.sagas";
import { modalsSagas } from "./modals/modals.sagas";
import { applicationSagas } from "./application/application.sagas";
import { techSagas } from "./tech/tech.sagas";
import { mediaSagas } from "./media/media.sagas";

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(messagingSagas),
    call(emailSagas),
    call(modalsSagas),
    call(applicationSagas),
    call(techSagas),
    call(mediaSagas)
  ]);
}

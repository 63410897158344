import UserActionTypes from "./user.types";

export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user
});
export const signInFailure = (errorMsg) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: errorMsg
});

export const emailSignInStart = (emailAndPassword) => ({
  type: UserActionTypes.EMAIL_SIGN_IN_START,
  payload: emailAndPassword
});

export const checkUserSession = () => ({
  type: UserActionTypes.CHECK_USER_SESSION
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START
});
export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS
});

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error
});

export const unauthorizedUser = () => ({
  type: UserActionTypes.UNAUTHORIZED_USER
});

export const setUserLanguage = (language) => ({
  type: UserActionTypes.SET_USER_LANGUAGE,
  payload: language
});

export const updateUserDetails = (userDetails) => ({
  type: UserActionTypes.UPDATE_USER_DETAILS,
  payload: userDetails
});

export const updateUserDetailsSuccess = (userDetails) => ({
  type: UserActionTypes.UPDATE_USER_DETAILS_SUCCESS,
  payload: userDetails
});

export const setBodyshop = (bodyshop) => ({
  type: UserActionTypes.SET_SHOP_DETAILS,
  payload: bodyshop
});

export const setInstanceId = (userInfo) => ({
  type: UserActionTypes.SET_INSTANCE_ID,
  payload: userInfo
});

export const checkInstanceId = (uid) => ({
  type: UserActionTypes.CHECK_INSTANCE_ID,
  payload: uid
});

export const setInstanceConflict = () => ({
  type: UserActionTypes.SET_INSTANCE_CONFLICT
});

export const setLocalFingerprint = (fingerprint) => ({
  type: UserActionTypes.SET_LOCAL_FINGERPRINT,
  payload: fingerprint
});

export const sendPasswordReset = (email) => ({
  type: UserActionTypes.SEND_PASSWORD_RESET_EMAIL_START,
  payload: email
});

export const sendPasswordResetAgain = (email) => ({
  type: UserActionTypes.SEND_PASSWORD_RESET_EMAIL_START_AGAIN,
  payload: email
});

export const sendPasswordResetFailure = (error) => ({
  type: UserActionTypes.SEND_PASSWORD_RESET_EMAIL_FAILURE,
  payload: error
});
export const sendPasswordResetSuccess = () => ({
  type: UserActionTypes.SEND_PASSWORD_RESET_EMAIL_SUCCESS
});

export const validatePasswordResetStart = (emailAndPin) => ({
  type: UserActionTypes.VALIDATE_PASSWORD_RESET_START,
  payload: emailAndPin
});

export const validatePasswordResetSuccess = () => ({
  type: UserActionTypes.VALIDATE_PASSWORD_RESET_SUCCESS
});

export const validatePasswordResetFailure = (error) => ({
  type: UserActionTypes.VALIDATE_PASSWORD_RESET_FAILURE,
  payload: error
});

export const setAuthlevel = (authlevel) => ({
  type: UserActionTypes.SET_AUTH_LEVEL,
  payload: authlevel
});

export const setCurrentEula = (eula) => ({
  type: UserActionTypes.SET_CURRENT_EULA,
  payload: eula
});

export const acceptEula = () => ({
  type: UserActionTypes.EULA_ACCEPTED
});

export const setImexShopId = (imexshopid) => ({
  type: UserActionTypes.SET_IMEX_SHOP_ID,
  payload: imexshopid
});

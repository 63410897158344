import { Card, FloatButton, Layout } from "antd";
import React, { lazy, Suspense, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import ErrorBoundary from "../../components/error-boundary/error-boundary.component";

import FeatureWrapper from "../../components/feature-wrapper/feature-wrapper.component";
import LoadingSpinner from "../../components/loading-spinner/loading-spinner.component";
import TechHeader from "../../components/tech-header/tech-header.component";
import TechSider from "../../components/tech-sider/tech-sider.component";
import UpdateAlert from "../../components/update-alert/update-alert.component";
import { selectTechnician } from "../../redux/tech/tech.selectors";
import InstanceRenderManager from "../../utils/instanceRenderMgr";
import "./tech.page.styles.scss";
import UpsellComponent, { upsellEnum } from "../../components/upsell/upsell.component.jsx";

const TimeTicketModalContainer = lazy(() => import("../../components/time-ticket-modal/time-ticket-modal.container"));
const EmailOverlayContainer = lazy(() => import("../../components/email-overlay/email-overlay.container.jsx"));
const PrintCenterModalContainer = lazy(
  () => import("../../components/print-center-modal/print-center-modal.container")
);
const TechLogin = lazy(() => import("../../components/tech-login/tech-login.component"));
const TechLookup = lazy(() => import("../tech-lookup/tech-lookup.container"));
const ProductionListPage = lazy(() => import("../production-list/production-list.container"));
const ProductionBoardPage = lazy(() => import("../production-board/production-board.container"));
const TechJobClock = lazy(() => import("../tech-job-clock/tech-job-clock.component"));
const TechShiftClock = lazy(() => import("../tech-shift-clock/tech-shift-clock.component"));
const TimeTicketModalTask = lazy(
  () => import("../../components/time-ticket-task-modal/time-ticket-task-modal.container")
);
const TechAssignedProdJobs = lazy(() => import("../tech-assigned-prod-jobs/tech-assigned-prod-jobs.component"));
const TechDispatchedParts = lazy(() => import("../tech-dispatched-parts/tech-dispatched-parts.page"));

const TaskUpsertModalContainer = lazy(() => import("../../components/task-upsert-modal/task-upsert-modal.container"));

const { Content } = Layout;

const mapStateToProps = createStructuredSelector({
  technician: selectTechnician
});
const mapDispatchToProps = (dispatch) => ({
  //setUserLanguage: language => dispatch(setUserLanguage(language))
});

export function TechPage({ technician }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = InstanceRenderManager({
      imex: t("titles.imexonline"),
      rome: t("titles.romeonline")
    });
  }, [t]);

  useEffect(() => {
    if (!technician) {
      navigate(`/tech/login`);
    }
  }, [technician, navigate]);

  return (
    <Layout className="tech-layout-container">
      <TechSider />
      <Layout>
        <UpdateAlert />
        <TechHeader />

        <TaskUpsertModalContainer />
        <Content className="tech-content-container">
          <ErrorBoundary>
            <Suspense
              fallback={
                <LoadingSpinner
                  message={t("general.labels.loadingapp", {
                    app: InstanceRenderManager({
                      imex: "$t(titles.imexonline)",
                      rome: "$t(titles.romeonline)"
                    })
                  })}
                />
              }
            >
              <FeatureWrapper
                featureName="techconsole"
                noauth={
                  <Card>
                    <UpsellComponent upsell={upsellEnum().techconsole.general} />
                  </Card>
                }
              >
                <TimeTicketModalContainer />
                <EmailOverlayContainer />
                <PrintCenterModalContainer />
                <TimeTicketModalTask />
                <Routes>
                  <Route path="/login" element={<TechLogin />} />
                  <Route path="/joblookup" element={<TechLookup />} />
                  <Route path="/list" element={<ProductionListPage />} />
                  <Route path="/jobclock" element={<TechJobClock />} />
                  <Route path="/shiftclock" element={<TechShiftClock />} />
                  <Route path="/board" element={<ProductionBoardPage />} />
                  <Route path="/assigned" element={<TechAssignedProdJobs />} />
                  <Route path="/dispatchedparts" element={<TechDispatchedParts />} />
                </Routes>
              </FeatureWrapper>
            </Suspense>
          </ErrorBoundary>
          <FloatButton.BackTop />
        </Content>
      </Layout>
    </Layout>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TechPage);

import dayjs from "../../utils/day";

const dateFormats = [
  "MMDDYYYY",
  "MMDDYY",
  "M/D/YYYY",
  "MM/D/YYYY",
  "M/DD/YYYY",
  "MM/DD/YYYY",
  "M/D/YY",
  "MM/D/YY",
  "M/DD/YY",
  "MM/DD/YY"
];

const timeFormats = ["h:mm A", "h:mmA", "h A", "hA", "hh:mm A", "hh:mm:ss A"];

const dateTimeFormats = [
  ...["M/D/YYYY", "MM/D/YYYY", "M/DD/YYYY", "MM/DD/YYYY", "M/D/YY", "MM/D/YY", "M/DD/YY", "MM/DD/YY"].flatMap(
    (dateFormat) => timeFormats.map((timeFormat) => `${dateFormat} ${timeFormat}`)
  ),

  ...["MMDDYYYY", "MMDDYY"].flatMap((dateFormat) => timeFormats.map((timeFormat) => `${dateFormat} ${timeFormat}`)),

  "M/D/YYYY",
  "MM/D/YYYY",
  "M/DD/YYYY",
  "MM/DD/YYYY",
  "M/D/YY",
  "MM/D/YY",
  "M/DD/YY",
  "MM/DD/YY",
  "MMDDYYYY",
  "MMDDYY"
];

const sanitizeInput = (input) =>
  input
    .trim()
    .toUpperCase()
    .replace(/\s*(am|pm)\s*/i, " $1")
    .replaceAll(".", "/")
    .replaceAll("-", "/");

export const fuzzyMatchDate = (dateString) => {
  const sanitizedInput = sanitizeInput(dateString);

  for (const format of dateFormats) {
    const parsedDate = dayjs(sanitizedInput, format, true);
    if (parsedDate.isValid()) {
      return parsedDate;
    }
  }

  for (const format of dateTimeFormats) {
    const parsedDateTime = dayjs(sanitizedInput, format, true);
    if (parsedDateTime.isValid()) {
      return parsedDateTime; // Return the dayjs object
    }
  }

  return null; // If no matching format is found
};

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { Spin } from "antd";

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

export default connect(mapStateToProps, null)(LandingPage);

export function LandingPage({ currentUser }) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/manage/jobs");
  }, [currentUser, navigate]);
  return <Spin />;
}

import { Layout, Typography } from "antd";
import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectTechnician } from "../../redux/tech/tech.selectors";
import { useTranslation } from "react-i18next";

const { Header } = Layout;

const mapStateToProps = createStructuredSelector({
  technician: selectTechnician
});
const mapDispatchToProps = (dispatch) => ({
  //setUserLanguage: language => dispatch(setUserLanguage(language))
});

export function TechHeader({ technician }) {
  const { t } = useTranslation();
  return (
    <Header style={{ textAlign: "center" }}>
      <Typography.Title style={{ color: "#fff" }}>
        {!!technician
          ? t("tech.labels.loggedin", {
              name: `${technician.first_name} ${technician.last_name}`
            })
          : t("tech.labels.notloggedin")}
      </Typography.Title>
    </Header>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TechHeader);

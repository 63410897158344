import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function PrivateRoute({ component: Component, isAuthorized, ...rest }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthorized) {
      navigate(`/signin?redirect=${location.pathname}`);
    }
  }, [isAuthorized, navigate, location]);

  return <Outlet />;
}

export default PrivateRoute;

import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";

import rootReducer from "./root.reducer";
import rootSaga from "./root.saga";
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});
const sagaMiddleWare = createSagaMiddleware();

const reduxSyncConfig = {
  whitelist: [
    "ADD_RECENT_ITEM" //"SET_SHOP_DETAILS"
  ]
};

const middlewares = [sagaMiddleWare, createStateSyncMiddleware(reduxSyncConfig)];

if (import.meta.env.DEV) {
  middlewares.push(createLogger({ collapsed: true, diff: true }));
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }).concat(middlewares),
  // middleware: middlewares,
  devTools: import.meta.env.DEV,
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer)
});

sagaMiddleWare.run(rootSaga);
initMessageListener(store);

export const persistor = persistStore(store);
const e = { store, persistStore };
export default e;

if (window.Cypress) {
  window.store = store;
}

import UserActionTypes from "./user.types";

const INITIAL_STATE = {
  currentUser: {
    authorized: null,
    eulaIsAccepted: false
    //language: "en-US"
  },
  bodyshop: null,
  loginLoading: false,
  fingerprint: null,
  error: null,
  conflict: false,
  passwordreset: {
    email: null,
    error: null,
    success: false,
    loading: false
  },
  authLevel: 0,
  currentEula: null
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_LOCAL_FINGERPRINT:
      return { ...state, fingerprint: action.payload };
    case UserActionTypes.SET_INSTANCE_ID:
      return { ...state, conflict: false };
    case UserActionTypes.SET_INSTANCE_CONFLICT:
      return { ...state, conflict: true };
    case UserActionTypes.EMAIL_SIGN_IN_START:
      return { ...state, loginLoading: true };
    case UserActionTypes.VALIDATE_PASSWORD_RESET_START:
    case UserActionTypes.SEND_PASSWORD_RESET_EMAIL_START:
      return {
        ...state,
        passwordreset: {
          email: action.payload,
          error: null,
          success: false,
          loading: true
        }
      };
    case UserActionTypes.SEND_PASSWORD_RESET_EMAIL_START_AGAIN:
      return {
        ...state,
        passwordreset: {
          email: action.payload,
          error: null,
          success: true,
          loading: true
        }
      };
    case UserActionTypes.VALIDATE_PASSWORD_RESET_FAILURE:
    case UserActionTypes.SEND_PASSWORD_RESET_EMAIL_FAILURE:
      return { ...state, passwordreset: { error: action.payload } };
    case UserActionTypes.VALIDATE_PASSWORD_RESET_SUCCESS:
    case UserActionTypes.SEND_PASSWORD_RESET_EMAIL_SUCCESS:
      return {
        ...state,
        passwordreset: {
          ...state.passwordreset,
          success: true,
          loading: false
        }
      };
    case UserActionTypes.EULA_ACCEPTED:
      return {
        ...state,
        currentUser: { ...state.currentUser, eulaIsAccepted: true },
        currentEula: null
      };
    case UserActionTypes.SIGN_IN_SUCCESS:
      const { currentEula, ...currentUser } = action.payload;
      return {
        ...state,
        loginLoading: false,
        currentUser: currentUser,
        currentEula,
        error: null
      };
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        currentUser: { authorized: false },
        error: null
      };
    case UserActionTypes.UNAUTHORIZED_USER:
      return {
        ...state,
        error: null,
        currentUser: { authorized: false }
      };
    case UserActionTypes.SET_USER_LANGUAGE:
      return {
        ...state,
        language: action.payload
      };
    case UserActionTypes.UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload //Spread current user details in.
        }
      };

    case UserActionTypes.SET_SHOP_DETAILS:
      return {
        ...state,
        bodyshop: action.payload
      };
    case UserActionTypes.SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_OUT_FAILURE:
    case UserActionTypes.EMAIL_SIGN_UP_FAILURE:
      return {
        ...state,
        loginLoading: false,
        error: action.payload
      };
    case UserActionTypes.SET_AUTH_LEVEL:
      return { ...state, authLevel: action.payload };
    case UserActionTypes.SET_IMEX_SHOP_ID:
      return {
        ...state,
        imexshopid: action.payload
      };
    default:
      return state;
  }
};

export default userReducer;

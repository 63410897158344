import { gql } from "@apollo/client";

export const INSERT_CONVERSATION_TAG = gql`
  mutation INSERT_CONVERSATION_TAG($conversationId: uuid!, $jobId: uuid!) {
    insert_job_conversations(
      objects: { conversationid: $conversationId, jobid: $jobId }
      on_conflict: { constraint: job_conversations_pkey, update_columns: jobid }
    ) {
      returning {
        jobid
        conversationid
        id
        conversation {
          id
          job_conversations {
            id
            jobid
            conversationid
            job {
              ownr_fn
              ownr_ln
              ownr_co_nm
            }
          }
        }
      }
    }
  }
`;

export const REMOVE_CONVERSATION_TAG = gql`
  mutation REMOVE_CONVERSATION_TAG($conversationId: uuid!, $jobId: uuid!) {
    delete_job_conversations(where: { _and: { jobid: { _eq: $jobId }, conversationid: { _eq: $conversationId } } }) {
      affected_rows
    }
  }
`;

import "./utils/sentry"; //Must be first.
import * as Sentry from "@sentry/react";
import { ConfigProvider } from "antd";
import Dinero from "dinero.js";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { registerSW } from "virtual:pwa-register";
import AppContainer from "./App/App.container";
import LoadingSpinner from "./components/loading-spinner/loading-spinner.component";
import "./index.css";
import { persistor, store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import "./translations/i18n";
import "./utils/CleanAxios";

window.global ||= window;

registerSW({ immediate: true });

// Dinero.defaultCurrency = "CAD";
// Dinero.globalLocale = "en-CA";
Dinero.globalRoundingMode = "HALF_EVEN";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV6(createBrowserRouter);

const router = sentryCreateBrowserRouter(createRoutesFromElements(<Route path="*" element={<AppContainer />} />));

if (import.meta.env.DEV) {
  let styles =
    "font-weight: bold; font-size: 50px;color: red;  6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) ";
  console.log("%c %s", styles, `VER: ${import.meta.env.VITE_APP_INSTANCE}`);
}

function App() {
  return (
    <PersistGate loading={<LoadingSpinner message="Restoring your settings..." />} persistor={persistor}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </PersistGate>
  );
}

// Used for ANTD Component Tokens
// https://ant.design/docs/react/migrate-less-variables
ReactDOM.createRoot(document.getElementById("root")).render(
  <ConfigProvider>
    <App />
  </ConfigProvider>
);

reportWebVitals();

const MessagingActionTypes = {
  TOGGLE_CHAT_VISIBLE: "TOGGLE_CHAT_VISIBLE",
  SEND_MESSAGE: "SEND_MESSAGE",
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
  SEND_MESSAGE_FAILURE: "SEND_MESSAGE_FAILURE",
  SET_SELECTED_CONVERSATION: "SET_SELECTED_CONVERSATION",
  OPEN_CHAT_BY_PHONE: "OPEN_CHAT_BY_PHONE",
  SET_MESSAGE: "SET_MESSAGE"
};
export default MessagingActionTypes;

import { gql } from "@apollo/client";

export const GET_DOCUMENT_BY_PK = gql`
  query GET_DOCUMENT_BY_PK($documentId: uuid!) {
    documents_by_pk(id: $documentId) {
      id
      name
      key
      type
      size
      takenat
      extension
      jobid
    }
  }
`;

export const GET_DOCUMENTS_BY_JOB = gql`
  query GET_DOCUMENTS_BY_JOB($jobId: uuid!) {
    jobs_by_pk(id: $jobId) {
      id
      ro_number
    }
    documents_aggregate(where: { jobid: { _eq: $jobId } }) {
      aggregate {
        sum {
          size
        }
      }
    }
    documents(order_by: { takenat: desc }, where: { jobid: { _eq: $jobId } }) {
      id
      name
      key
      type
      size
      takenat
      extension
      bill {
        id
        invoice_number
        date
        vendor {
          id
          name
        }
      }
    }
  }
`;

export const GET_DOC_SIZE_BY_JOB = gql`
  query GET_DOC_SIZE_BY_JOB($jobId: uuid!) {
    documents_aggregate(where: { jobid: { _eq: $jobId } }) {
      aggregate {
        sum {
          size
        }
      }
    }
  }
`;

export const INSERT_NEW_DOCUMENT = gql`
  mutation INSERT_NEW_DOCUMENT($docInput: [documents_insert_input!]!) {
    insert_documents(objects: $docInput) {
      returning {
        id
        name
        key
        size
        takenat
      }
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation DELETE_DOCUMENT($id: uuid) {
    delete_documents(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;
export const DELETE_DOCUMENTS = gql`
  mutation DELETE_DOCUMENTS($ids: [uuid!]!) {
    delete_documents(where: { id: { _in: $ids } }) {
      returning {
        id
      }
    }
  }
`;
export const QUERY_TEMPORARY_DOCS = gql`
  query QUERY_TEMPORARY_DOCS {
    documents(where: { jobid: { _is_null: true } }, order_by: { takenat: desc }) {
      id
      name
      key
      type
      extension
      size
      takenat
    }
  }
`;

export const UPDATE_DOCUMENT = gql`
  mutation UPDATE_DOCUMENT($id: uuid!, $document: documents_set_input!) {
    update_documents_by_pk(pk_columns: { id: $id }, _set: $document) {
      billid
      bodyshopid
      extension
      id
      jobid
      name
      type
      key
      size
      takenat
    }
  }
`;

import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { getJobMediaError, setJobMedia } from "./media.actions";
import MediaActionTypes from "./media.types";
import cleanAxios from "../../utils/CleanAxios";
import normalizeUrl from "normalize-url";

export function* onSetJobMedia() {
  yield takeLatest(MediaActionTypes.GET_MEDIA_FOR_JOB, getJobMedia);
}

export function* getJobMedia({ payload: jobid }) {
  try {
    const bodyshop = yield select((state) => state.user.bodyshop);
    const localmediaserverhttp = bodyshop.localmediaserverhttp.trim();

    if (localmediaserverhttp && localmediaserverhttp !== "") {
      const imagesFetch = yield cleanAxios.post(
        `${localmediaserverhttp}/jobs/list`,
        {
          jobid
        },
        { headers: { ims_token: bodyshop.localmediatoken } }
      );
      const documentsFetch = yield cleanAxios.post(
        `${localmediaserverhttp}/bills/list`,
        {
          jobid
        },
        { headers: { ims_token: bodyshop.localmediatoken } }
      );

      yield put(
        setJobMedia({
          jobid,
          media: [
            ...imagesFetch.data.map((d, idx) => {
              return {
                ...d,
                src: normalizeUrl(`${localmediaserverhttp}/${d.src}`),
                thumbnail: normalizeUrl(`${localmediaserverhttp}/${d.thumbnail}`),
                ...(d.optimized && {
                  optimized: normalizeUrl(`${localmediaserverhttp}/${d.optimized}`)
                }),
                isSelected: false,
                key: idx
              };
            }),
            ...documentsFetch.data.map((d, idx) => {
              return {
                ...d,
                src: normalizeUrl(`${localmediaserverhttp}/${d.src}`),
                thumbnail: normalizeUrl(`${localmediaserverhttp}/${d.thumbnail}`),

                isSelected: false,
                key: idx
              };
            })
          ]
        })
      );
    }
  } catch (error) {
    yield put(getJobMediaError(error));
  }
}

export function* onSetBillMedia() {
  yield takeLatest(MediaActionTypes.GET_MEDIA_FOR_BILL, getBillMedia);
}

export function* getBillMedia({ payload: { jobid, invoice_number } }) {
  try {
    const bodyshop = yield select((state) => state.user.bodyshop);
    const localmediaserverhttp = bodyshop.localmediaserverhttp.trim();

    if (localmediaserverhttp && localmediaserverhttp !== "") {
      const documentsFetch = yield cleanAxios.post(
        `${localmediaserverhttp}/bills/list`,
        {
          jobid,
          invoice_number
        },
        { headers: { ims_token: bodyshop.localmediatoken } }
      );

      yield put(
        setJobMedia({
          jobid,
          media: [
            ...documentsFetch.data.map((d, idx) => {
              return {
                ...d,
                src: normalizeUrl(`${localmediaserverhttp}/${d.src}`),
                thumbnail: normalizeUrl(`${localmediaserverhttp}/${d.thumbnail}`),
                ...(d.optimized && {
                  optimized: normalizeUrl(`${localmediaserverhttp}/${d.optimized}`)
                }),
                isSelected: false,
                key: idx
              };
            })
          ]
        })
      );
    }
  } catch (error) {
    yield put(getJobMediaError(error));
  }
}

export function* mediaSagas() {
  yield all([call(onSetJobMedia), call(onSetBillMedia)]);
}

import { ApolloProvider } from "@apollo/client";
import { SplitFactoryProvider, useSplitClient } from "@splitsoftware/splitio-react";
import { ConfigProvider } from "antd";
import enLocale from "antd/es/locale/en_US";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import GlobalLoadingBar from "../components/global-loading-bar/global-loading-bar.component";
import client from "../utils/GraphQLClient";
import App from "./App";
import * as Sentry from "@sentry/react";
import themeProvider from "./themeProvider";
import { CookiesProvider } from "react-cookie";

// Base Split configuration
const config = {
  core: {
    authorizationKey: import.meta.env.VITE_APP_SPLIT_API,
    key: "anon" // Default key, overridden dynamically by SplitClientProvider
  }
};

// Custom provider to manage the Split client key based on imexshopid from Redux
function SplitClientProvider({ children }) {
  const imexshopid = useSelector((state) => state.user.imexshopid); // Access imexshopid from Redux store
  const splitClient = useSplitClient({ key: imexshopid || "anon" }); // Use imexshopid or fallback to "anon"

  useEffect(() => {
    if (splitClient && imexshopid) {
      // Log readiness for debugging; no need for ready() since isReady is available
      console.log(`Split client initialized with key: ${imexshopid}, isReady: ${splitClient.isReady}`);
    }
  }, [splitClient, imexshopid]);

  return children;
}

function AppContainer() {
  const { t } = useTranslation();

  return (
    <CookiesProvider>
      <ApolloProvider client={client}>
        <ConfigProvider
          input={{ autoComplete: "new-password" }}
          locale={enLocale}
          theme={themeProvider}
          form={{
            validateMessages: {
              // eslint-disable-next-line no-template-curly-in-string
              required: t("general.validation.required", { label: "${label}" })
            }
          }}
        >
          <GlobalLoadingBar />
          <SplitFactoryProvider config={config}>
            <SplitClientProvider>
              <App />
            </SplitClientProvider>
          </SplitFactoryProvider>
        </ConfigProvider>
      </ApolloProvider>
    </CookiesProvider>
  );
}

export default Sentry.withProfiler(AppContainer);
